import axios from "axios";

const DEVLOPMENT_MODE = false;
var rootPath = "";
if (DEVLOPMENT_MODE === true) {
  //rootPath = "http://192.168.100.32:4002";
  rootPath = "http://127.0.0.1:5002";
  //rootPath = "http://192.168.205.245:4002";
} else {
  rootPath = "https://israpi.kbc.org.pk";
  //rootPath = "http://174.138.25.97:5002";
}

rootPath = process.env.REACT_APP_API_URL;

//const path = "http://localhost:4000";
//const path = "192.168.100.5:4000";
export function getLoggedInUser() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/LoggedInUser",
  });
  return data;
}

export function Login(username, password) {
  var data = axios({
    method: "POST",
    data: {
      username: username,
      password: password,
    },
    withCredentials: true,
    url: rootPath + "/login",
  });
  return data;
}
export function Logout() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/logout",
  });
  return data;
}

export function Signup(props) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      fullname: props.fullname,
      username: props.username,
      phonenumber: props.phonenumber,
      city: props.city,
      school: props.school,
      password: props.password,
    },
    withCredentials: true,
    url: rootPath + "/signup",
  });
  return data;
}

export function AddSchoolInfo(form) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      address: form.SchoolAddress,
      emailaddress: form.SchoolEmailAddress,
      website: form.SchoolWebsite,
      coach: {
        Name: form.CoachFullName,
        Email: form.CoachEmailAddress,
        PhoneNumber: form.CoachPhoneNumber,
      },
      principal: {
        Name: form.PrincipalFullName,
        Title: form.PrincipalTitle,
        Email: form.PrincipalEmailAddress,
        PhoneNumber: form.PrincipalPhoneNumber,
        ContactType: form.PrincipalContactType,
      },
      rowingcoordinator: {
        Name: form.RowingCoordinatorFullName,
        Title: form.RowingCoordinatorTitle,
        Email: form.RowingCoordinatorEmailAddress,
        PhoneNumber: form.RowingCoordinatorPhoneNumber,
        ContactType: form.RowingCoordinatorContactType,
      },
      agmvotename: form.AGMName,
      schooldeclaration: form.SchoolDeclaration,
      bestcoxnomination: form.COXNomination,
      bestcoxnominationjustification: form.COXNominationJustification,
      schoollogo: form.SchoolLogo,
    },
    withCredentials: true,
    url: rootPath + "/AddSchoolInfo",
  });
  return data;
}

export function UpdateSchoolInfo(form) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      address: form.SchoolAddress,
      emailaddress: form.SchoolEmailAddress,
      website: form.SchoolWebsite,
      coach: {
        Name: form.CoachFullName,
        Email: form.CoachEmailAddress,
        PhoneNumber: form.CoachPhoneNumber,
      },
      principal: {
        Name: form.PrincipalFullName,
        Title: form.PrincipalTitle,
        Email: form.PrincipalEmailAddress,
        PhoneNumber: form.PrincipalPhoneNumber,
        ContactType: form.PrincipalContactType,
      },
      rowingcoordinator: {
        Name: form.RowingCoordinatorFullName,
        Title: form.RowingCoordinatorTitle,
        Email: form.RowingCoordinatorEmailAddress,
        PhoneNumber: form.RowingCoordinatorPhoneNumber,
        ContactType: form.RowingCoordinatorContactType,
      },
      agmvotename: form.AGMName,
      bestcoxnomination: form.COXNomination,
      bestcoxnominationjustification: form.COXNominationJustification,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolInfo",
  });
  return data;
}

export function AddEventEntries(EventEntries) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      evententries: EventEntries,
    },
    withCredentials: true,
    url: rootPath + "/AddEventEntries",
  });
  return data;
}
export function AddNewRower(form) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      Name: form.StudentFullName,
      YOB: form.StudentYOB,
      Gender: form.StudentGender,
      EmailAddress: form.StudentEmailAddress,
      PhoneNumber: form.StudentPhoneNumber,
      Photograph: form.StudentPicture,
      Nationality: form.StudentNationality,
      CNIC: form.StudentCNIC,
      CNICNumber: form.StudentCNICNumber,
      Declaration: form.StudentDeclaration,
      Vaccination: form.StudentVaccination,
      KBCMember: form.KBCMember,
      KBCNumber: form.StudentKBCNumber,
      PreviousKBC: form.PreviousRegatta,
      ShirtSize: form.ShirtSize,
    },
    withCredentials: true,
    url: rootPath + "/AddNewRower",
  });
  return data;
}

export function UpdateRower(rowerID, Name, EmailAddress, PhoneNumber) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      rowerID,
      Name,
      EmailAddress,
      PhoneNumber,
    },
    withCredentials: true,
    url: rootPath + "/UpdateRower",
  });
  return data;
}
export function GetRowers() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/Rowers",
  });
  return data;
}
export function GetCoxes() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/Coxes",
  });
  return data;
}

export function AddNewRaceEntry(
  RaceName,
  AgeGroup,
  Gender,
  Class,
  Rowers,
  Coxers,
  SubRowers,
  SubCoxers
) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      RaceName: RaceName,
      AgeGroup: AgeGroup,
      Gender: Gender,
      Class: Class,
      Rowers: Rowers,
      Coxers: Coxers,
      SubRowers: SubRowers,
      SubCoxers: SubCoxers,
    },
    withCredentials: true,
    url: rootPath + "/AddNewRaceEntry",
  });
  return data;
}

export function DeleteRaceEntry(RaceName) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      RaceName: RaceName,
    },
    withCredentials: true,
    url: rootPath + "/DeleteRaceEntry",
  });
  return data;
}

export function GetRaceEntries() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetRaceEntries",
  });
  return data;
}

export function UpdateTeamRoles(captain, vicecaptain, coxes) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      captain: captain,
      vicecaptain: vicecaptain,
      coxes: coxes,
    },
    withCredentials: true,
    url: rootPath + "/UpdateTeamRoles",
  });
  return data;
}

export function DownloadFile(FileURL) {
  window.open(rootPath + `/DownloadFile/${encodeURIComponent(FileURL)}`);
}

export function UpdateSchoolBestCoxNomination(file) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      file: file,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolBestCoxNomination",
  });
  return data;
}
export function UpdateSchoolLogo(file) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      file: file,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolLogo",
  });
  return data;
}

export function UpdateSchoolDeclaration(file) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      file: file,
    },
    withCredentials: true,
    url: rootPath + "/UpdateSchoolDeclaration",
  });
  return data;
}

export function GetRower(id) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/Rower/${id}`,
  });
  return data;
}

export function GetIsRowerValidToDelete(id) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/isStudentValidToDelete/${id}`,
  });
  return data;
}

export function GetDeleteStudent(id) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/deletestudent/${id}`,
  });
  return data;
}

export function UpdateRaceEntry(
  RaceName,
  AgeGroup,
  Gender,
  Class,
  Rowers,
  Coxers,
  SubRowers,
  SubCoxers
) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      RaceName: RaceName,
      AgeGroup: AgeGroup,
      Gender: Gender,
      Class: Class,
      Rowers: Rowers,
      Coxers: Coxers,
      SubRowers: SubRowers,
      SubCoxers: SubCoxers,
    },
    withCredentials: true,
    url: rootPath + "/UpdateRaceEntry",
  });
  return data;
}

export function GetStudentEvents(id) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/StudentEvents/${id}`,
  });
  return data;
}

export function GetUnAssignedStudents() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/UnAssgignedStudents",
  });
  return data;
}

export function GetFormCheckList() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/FormCheckList",
  });
  return data;
}

export function SubmitFinalForm() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/FinalSubmitForm",
  });
  return data;
}

export function UpdateStudentPhotograph(file, rowername, kbcNumber, rowerID) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      file,
      rowername,
      kbcNumber,
      rowerID,
    },
    withCredentials: true,
    url: rootPath + "/UpdateStudentPhotograph",
  });
  return data;
}

export function UpdateStudentCNIC(file, rowername, kbcNumber, rowerID) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      file,
      rowername,
      kbcNumber,
      rowerID,
    },
    withCredentials: true,
    url: rootPath + "/UpdateStudentCNIC",
  });
  return data;
}

export function UpdateStudentDeclaration(file, rowername, kbcNumber, rowerID) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      file,
      rowername,
      kbcNumber,
      rowerID,
    },
    withCredentials: true,
    url: rootPath + "/UpdateStudentDeclaration",
  });
  return data;
}

export function GetCMSDocument(docname) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetCMSDoc/${docname}`,
  });
  return data;
}
export function UpdateRowerPhoneNumber(rowerID, phonenumber) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      rowerID,
      phonenumber,
    },
    withCredentials: true,
    url: rootPath + "/UpdateRowerPhoneNumber",
  });
  return data;
}

export function GetFileDocuments() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/api/filedocuments`,
  });
  return data;
}

export function GetOldRowerDetails(cnic) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/api/oldcollections/rower/${cnic}`,
  });
  return data;
}
