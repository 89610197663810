import React from "react";
import { useEffect, useState, useContext } from "react";
import { GetUnAssignedStudents, GetRowers } from "../../APIManager";
import { Alert, Popover, Button, Spin, Tag } from "antd";
import styles from "./PaymentPage.module.css";
import { GetFeeChallanDD } from "./PDFLayout";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { UserContext } from "../../UserContext";
import { ConfigContext } from "../../ConfigContext";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const PaymentPage = () => {
  const [unassignedStudents, setUnassignedStudents] = useState({});
  const [rowers, SetRowers] = useState([]);
  const [user, setUser] = useContext(UserContext);
  let [config, setConfig] = useContext(ConfigContext);

  const [loading, SetLoading] = useState(true);

  const {
    RowerFees,
    SchoolFees,
    MaxSchoolFees,
    LateRowerFees,
    LateSchoolFees,
  } = config.cpConfig;

  var noOfRowers = rowers?.length;

  var deadline = new Date(config.cpConfig.SecondDeadlineDate);
  var currentDate = new Date();
  let difference = currentDate.getTime() - deadline.getTime();
  let daysOverDeadline = Math.floor(difference / (1000 * 3600 * 24));

  useEffect(() => {
    SetLoading(true); // Start loading when the effect is triggered

    Promise.all([GetUnAssignedStudents(), GetRowers()])
      .then(([unassignedStudentsRes, rowersRes]) => {
        setUnassignedStudents(unassignedStudentsRes.data);
        SetRowers(rowersRes.data);
      })
      .finally(() => {
        SetLoading(false); // Set loading to false after both promises resolve
      });
  }, []);

  if (loading) {
    return (
      <div>
        <h1 style={{ fontSize: "1.5em" }}>Loading</h1>
        <Spin size="large" />
      </div>
    );
  }

  let nf = new Intl.NumberFormat("en-US");

  var totalrowerFees = RowerFees * noOfRowers;
  var totalschoolfees =
    noOfRowers * SchoolFees > MaxSchoolFees
      ? Number(MaxSchoolFees)
      : SchoolFees * noOfRowers;
  var totalfees = totalrowerFees + totalschoolfees;
  var lateFees = 0;

  if (daysOverDeadline > 0) {
    // lateFees = totalfees * 2;
    lateFees =
      lateFees +
      (daysOverDeadline - 1) * (LateSchoolFees + noOfRowers * LateRowerFees);

    totalfees = totalfees + lateFees;
  }

  return (
    <div>
      <h1 style={{ fontSize: "2em" }}>Payment</h1>
      <div style={{ fontSize: "1.7em", marginBottom: "1em" }}>
        Payment Status:{" "}
        {!user.stagescomplete.includes("Payment") ? (
          <Tag style={{ fontSize: "1em", padding: "10px" }} color="red">
            Not Paid
          </Tag>
        ) : (
          <Tag style={{ fontSize: "1em", padding: "10px" }} color="green">
            Paid
          </Tag>
        )}
      </div>
      <UnAssignedError unassignedStudents={unassignedStudents} />
      <div className={styles.MainInvoiceContainer}>
        <hr />
        <h3>
          <label>Individual Entry Fee: </label>Rs. {nf.format(RowerFees)}/ Student
        </h3>
        {noOfRowers * SchoolFees >= MaxSchoolFees ? (
          <h3>
            <label>School Entry Fee:</label> Rs. {nf.format(MaxSchoolFees)}{" "}
            <i>(For {MaxSchoolFees / SchoolFees} or more rowers)</i>
          </h3>
        ) : (
          <h3>
            <label>School Entry Fee: </label>Rs. {nf.format(SchoolFees)} / Student{" "}
            <i>(For less than {MaxSchoolFees / SchoolFees} rowers)</i>
          </h3>
        )}
        <hr />
        <h3>
          <label>Number of Students: </label>
          {noOfRowers}
        </h3>
        <hr />
        <h3>
          <label>Individual Entry Fee Sub Total:</label>Rs.{" "}
          {nf.format(noOfRowers * RowerFees)}
        </h3>
        {noOfRowers * SchoolFees >= MaxSchoolFees ? (
          <h3>
            <label>School Entry Fee Sub Total:</label> Rs. {nf.format(MaxSchoolFees)}{" "}
          </h3>
        ) : (
          <h3>
            <label>School Entry Fee Sub Total:</label> Rs.{" "}
            {nf.format(noOfRowers * SchoolFees)}{" "}
          </h3>
        )}
        <hr />
        {daysOverDeadline > 0 && (
          <>
            {" "}
            <h3>
              <label>Days Late: </label>
              {daysOverDeadline}{" "}
            </h3>
            <h3>
              <label>Late penalty: </label>
              {nf.format(lateFees)}{" "}
            </h3>
            <hr />
          </>
        )}

        <h3 className={styles.TotalFeeBox}>
          <label>Total Fee: </label>Rs. {nf.format(totalfees)}
        </h3>
        <Button
          type="primary"
          onClick={async () => {
            const dd = await GetFeeChallanDD(
              user.schoolname,
              new Date().toLocaleDateString("en-GB"),
              noOfRowers,
              `Rs. ${nf.format(totalrowerFees)}`,
              `Rs. ${nf.format(totalschoolfees)}`,
              `Rs. ${nf.format(totalfees)}`,
              true,
              lateFees ? `Rs. ${nf.format(lateFees)}` : 0,
              config.cpConfig
            );
            var pdf = pdfMake.createPdf(dd);
            console.log(pdf);
            pdf.download("Fee Challan");
          }}
        >
          Download Fee Challan
        </Button>
      </div>
    </div>
  );
};

export default PaymentPage;

const UnAssignedError = ({ unassignedStudents }) => {
  if (
    unassignedStudents.IDs === undefined ||
    unassignedStudents.IDs?.length === 0
  ) {
    return null;
  }

  const content = (
    <div style={{ maxHeight: "30vh", overflow: "scroll" }}>
      <ul>
        {unassignedStudents.Names.map((name) => {
          return <li>{name}</li>;
        })}
      </ul>
    </div>
  );
  var no = unassignedStudents.IDs?.length;
  return (
    <Alert
      message="Warning"
      //description={`You have not assigned ${no} students to any race However, They will stil be incuded in the total registration fees. It is best if you either assign them to a race or delete them from the Team Registration Page`}
      description={
        <div>
          You have not assigned <strong>{no}</strong> students to any race
          <br />
          They will still be incuded in the total registration fees. <br />
          You may assign them to a race or delete them from the Team
          Registration Page.
          <br />
          <Popover content={content} title="Unassigned Students">
            <strong>Hover here for names</strong>
          </Popover>
        </div>
      }
      type="warning"
      showIcon
    />
  );
};
