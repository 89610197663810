import React, { useContext } from "react";
import styles from "./AppHeader.module.css";
import ISRIcon from "../../Static/isr_logo.png";
import KBCIcon from "../../Static/kbc_logo.png";
import { ConfigContext } from "../../ConfigContext";

export default function AppHeader() {
  let [config, setConfig] = useContext(ConfigContext);

  return (
    <div className={styles.MainBar}>
      <img
        src={
          "https://kbcisr.s3.ap-southeast-2.amazonaws.com/ISR2025/static/logos/ISRLogo.png"
        }
      />
      <span className={styles.AppHeading}>ISR {config.cpConfig.EventYear}</span>
      <img
        src={
          "https://kbcisr.s3.ap-southeast-2.amazonaws.com/ISR2025/static/logos/KBCLogo.png"
        }
        style={{ height: "45px" }}
      />
    </div>
  );
}
