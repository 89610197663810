import "./App.css";
import "antd/dist/antd.less";
import { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { UserContext, UserProvider } from "./UserContext";
import { getLoggedInUser, GetCMSDocument } from "./APIManager";
import LoginPage from "./Components/LoginPage/LoginPage";
import MainLayout from "./Components/MainLayout/MainLayout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ConfigContext } from "./ConfigContext";

import { GetAgeBracketInfo } from "./utility";

import { Modal } from "antd";

import { useIdleTimer } from "react-idle-timer";
const { confirm } = Modal;

function App() {
  const [user, setUser] = useContext(UserContext);
  const [config, setConfig] = useContext(ConfigContext);

  const [isResolved, setResolved] = useState(false);
  const [fetchedConfig, SetFetchedConfig] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const [isInactive, SetIsInactive] = useState(false);

  useEffect(() => {
    FetchUserDetails(
      isResolved,
      setUser,
      currentPath,
      setCurrentPath,
      setResolved
    );
  });

  useEffect(() => {
    FetchConfig();
  }, []);

  function FetchConfig() {
    GetCMSDocument("ControlPanelFormSetup").then((res) => {
      const newConfig = {
        ...config,
        cpConfig: { ...res.data.Data, ...InjectConfig(res.data.Data) },
      };
      setConfig(newConfig);
      console.log(newConfig);
      SetFetchedConfig(true);
    });
  }

  function InjectConfig(cpConfig) {
    const {
      U25YOBLowerLimit,
      U21YOBLowerLimit,
      U18YOBLowerLimit,
      U15YOBLowerLimit,
    } = GetAgeBracketInfo(cpConfig);

    const U25AllowedYOBs = `${U25YOBLowerLimit}, ${U25YOBLowerLimit + 1}, ${
      U25YOBLowerLimit + 2
    }, and ${U25YOBLowerLimit + 3}`;

    const U21AllowedYOBs = `${U21YOBLowerLimit}, ${U21YOBLowerLimit + 1}, and ${
      U21YOBLowerLimit + 2
    }`;

    const U18AllowedYOBs = `${U18YOBLowerLimit}, ${U18YOBLowerLimit + 1}, and ${
      U18YOBLowerLimit + 2
    }`;

    const U15AllowedYOBs = `${U15YOBLowerLimit} or after`;

    return {
      U25YOBLowerLimit,
      U21YOBLowerLimit,
      U18YOBLowerLimit,
      U15YOBLowerLimit,
      U25AllowedYOBs,
      U21AllowedYOBs,
      U18AllowedYOBs,
      U15AllowedYOBs,
    };
  }

  function onIdle() {
    if (isInactive) {
      return;
    }
    SetIsInactive(true);
    confirm({
      title: "Session Timed Out",
      icon: <ExclamationCircleOutlined />,
      content: "Your session has timed out due to inactivity",
      okText: "Reload Page",
      okType: "primary",
      cancelText: "",
      cancelButtonProps: { hidden: true },
      onOk() {
        window.location.reload();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 10,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  if (!isResolved || !fetchedConfig) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <dotlottie-player
          src="https://lottie.host/b8dd0d20-69a4-410a-8f08-6fdcdfea7224/tVLMap1DA4.json"
          background="transparent"
          speed="1"
          style={{
            width: "40em",
            height: "40em",
            marginLeft: "-7em",
            marginTop: "-7em",
          }}
          loop
          autoplay
        ></dotlottie-player>
        <h1 style={{ marginTop: "-3em" }}>Loading</h1>
      </div>
    );
  }

  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="*">
          <MainLayout />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

function FetchUserDetails(
  isResolved,
  setUser,
  currentPath,
  setCurrentPath,
  setResolved
) {
  //  console.log("hooray");
  if (isResolved !== true) {
    getLoggedInUser().then((res) => {
      // console.log(res);
      setUser(res.data);
      setCurrentPath(window.location.href);
      setResolved(true);
    });
    //setResolved(true);
  } else {
    if (currentPath !== window.location.href) {
      getLoggedInUser().then((res) => {
        // console.log(res);
        setUser(res.data);
        setResolved(true);
      });
      setCurrentPath(window.location.href);
    }
  }
}
