import React, { useState, useContext, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { UserContext } from "../../UserContext";
import styles from "./EventEntries.module.scss";

import { Card, notification, Button, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { SplitEntriesByCategory, CatCodeToFullform } from "../../utility";

import { GetRaceEntries, AddEventEntries } from "../../APIManager";

import { ListOfEvents } from "../../constants";
import userEvent from "@testing-library/user-event";
import { ConfigContext } from "../../ConfigContext";

const gridStyle = {
  width: "100%",
  height: "100%",
  textAlign: "center",
  fontWeight: "600",
};

const { confirm } = Modal;

const ViewEventEntries = () => {
  const [user, setUser] = useContext(UserContext);
  const [config, setConfig] = useContext(ConfigContext);

  const [eventEntries, SetEventEntries] = useState(user.evententries);

  const proccessedListOfEvents = SplitEntriesByCategory(ListOfEvents);

  const [raceEntries, SetRaceEntries] = useState([]);

  const [dataFetched, SetDataFetched] = useState(false);
  const [isSubmitting, SetIsSubmitting] = useState(false);

  const [submitButtonState, SetSubmitButtonState] = useState(false);

  const [isEditable, SetIsEditable] = useState(false);
  const [saveButtonText, SetSaveButtonText] = useState("");

  useEffect(() => {
    GetRaceEntries().then((res) => {
      SetRaceEntries(res.data);
      SetDataFetched(true);
    });
  }, []);

  useEffect(() => {
    var today = new Date();
    var deadline = new Date(config.cpConfig.FirstDeadlineDate);
    var beforeDeadline = today < deadline;

    console.log(beforeDeadline);

    if (user.stagescomplete.includes("Final Submission")) {
      SetIsEditable(false);
      SetSaveButtonText("Form already submitted");
    } else {
      if (beforeDeadline) {
        SetIsEditable(true);
        SetSaveButtonText("Save");
      } else if (
        user.otherattr?.includes("EventEntriesExtension") ||
        (user.evententries.length > 1 &&
          !user.stagescomplete.includes("Event Entries"))
      ) {
        SetIsEditable(true);
        SetSaveButtonText("Submit");
      } else {
        if (eventEntries.length < 1 && !config.cpConfig.LockOn1stDeadline) {
          SetSaveButtonText("Submit");
          SetIsEditable(true);
        } else {
          SetIsEditable(false);
          SetSaveButtonText("Event entries deadline passed");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isEditable) {
      SetSubmitButtonState(GetSubmitButtonState());
    }
  }, [eventEntries]);

  function GetSubmitButtonState() {
    return true;
    if (eventEntries.sort().join(",") == user.evententries.sort().join(",")) {
      return false;
    } else {
      return true;
    }
  }

  function OnSubmitClicked() {
    if (!isEditable) {
      return;
    }

    if (eventEntries.length < 1) {
      message.error("Choose atleast 1 event to save");
      return;
    }

    confirm({
      title: "Confirm Submission",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to submit. You cant edit these after ${config.cpConfig.FirstDeadlineText}.`,
      okText: "Submit",
      okType: "primary",
      cancelText: "cancel",
      onOk() {
        SetIsSubmitting(true);
        AddEventEntries(eventEntries).then(() => {
          window.location.reload();
        });
      },
      onCancel() {},
    });
  }
  if (!dataFetched || isSubmitting) {
    return <h1>Loading</h1>;
  }

  return (
    <div className={styles.EventEntryGrid}>
      <Prompt
        when={submitButtonState}
        message={(location) =>
          "You have unsaved progress. Do you want to leave this page?"
        }
      />
      <h1 className={styles.SubHeading}>Registered Events:</h1>
      {!isEditable && user.evententries.length > 0 && (
        <h2>
          Note: Your event entries have been submitted and can no longer be
          edited
        </h2>
      )}
      {!isEditable && user.evententries.length == 0 && (
        <h2 style={{ color: "Red" }}>
          Note: The deadline for event entries has passed. Contact the ISR
          Secretary for more information
        </h2>
      )}
      {user.otherattr.includes("EventEntriesExtension") && (
        <h2 style={{ color: "Red" }}>
          Note: You have been allowed an extension for submitting event entries
        </h2>
      )}
      <div>
        {Object.entries(proccessedListOfEvents).map(([cat, races]) => {
          return (
            <>
              <Card className={styles.testClass} title={CatCodeToFullform(cat)}>
                <div className={styles.gridContainer}>
                  {races.map((race) => {
                    return (
                      <div className={styles.gridItem}>
                        <RaceButton
                          raceName={race}
                          isSelected={eventEntries.includes(race)}
                          disabled={user.stagescomplete.includes(
                            "Final Submission"
                          )}
                          onClick={(v) => {
                            if (isEditable) {
                              if (
                                race.includes("Under 25") &&
                                !user.university
                              ) {
                                notification.error({
                                  message: `You cannot enter for this event`,
                                  description:
                                    "Only universities can enter for Under 25 events.",
                                  placement: "topRight",
                                  duration: 10,
                                  maxCount: 1,
                                });
                                return;
                              }
                              if (
                                !race.includes("Under 25") &&
                                user.university
                              ) {
                                notification.error({
                                  message: `You cannot enter for this event`,
                                  description:
                                    "Universities can only enter for Under 25 events.",
                                  placement: "topRight",
                                  duration: 10,
                                  maxCount: 1,
                                });
                                return;
                              }

                              onRaceClicked(
                                v,
                                eventEntries,
                                race,
                                SetEventEntries,
                                raceEntries
                              );
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </Card>
              <br />
            </>
          );
        })}
      </div>
      <Button
        size={"large"}
        block
        type="primary"
        htmlType="submit"
        disabled={!submitButtonState}
        onClick={OnSubmitClicked}
      >
        {saveButtonText}
      </Button>
    </div>
  );
};

export default ViewEventEntries;

function onRaceClicked(
  addEntry,
  eventEntries,
  race,
  SetEventEntries,
  raceEntries
) {
  if (addEntry) {
    SetEventEntries([...eventEntries, race]);
  } else {
    if (raceEntries.filter((item) => item.RaceName === race).length > 0) {
      openNotification();
      return;
    }

    SetEventEntries([...eventEntries.filter((item) => item !== race)]);
  }
}

const RaceButton = ({ raceName, isSelected, onClick, disabled }) => {
  return (
    <Card
      hoverable
      style={{
        ...gridStyle,
        background: isSelected ? "#f6ffed" : "#fff0f6",
        borderColor: isSelected ? "#b7eb8f" : "#ffadd2",
        margin: "1%",
      }}
      onClick={() => {
        if (!disabled) {
          onClick(!isSelected);
        }
      }}
    >
      {raceName}
    </Card>
  );
};

const openNotification = () => {
  notification.error({
    message: `You cannot remove this event entry`,
    description:
      "You have already assigned rowers to this race, hence, you cannot delete it from the event entries. Unassign rowers from this race via the Event Registrations Page before deleting.",
    placement: "topRight",
    duration: 8,
    maxCount: 1,
  });
};
