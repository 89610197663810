import React, { useState, useContext, useEffect } from "react";
import { GetRowers, UpdateTeamRoles, GetRaceEntries } from "../../APIManager";
import { Form, Select, Button, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { UserContext } from "../../UserContext";
import styles from "./TeamRoles.module.css";
const { confirm } = Modal;
const { Option, OptGroup } = Select;
const TeamRoles = () => {
  const [user, setUser] = useContext(UserContext);
  const [validCaptains, SetValidCaptains] = useState([]);
  const [validRowPasters, SetValidRowPasters] = useState([]);
  const [rowers, SetRowers] = useState([]);
  const [coxes, SetCoxes] = useState([]);
  const [raceEntries, SetRaceEntries] = useState([]);
  const [activeCoxes, SetActiveCoxes] = useState([]);
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const [form] = Form.useForm();
  useEffect(() => {
    GetRowers().then((res) => {
      SetRowers(res.data);
    });

    GetRaceEntries().then((res) => {
      SetRaceEntries(res.data);
      ProcessActiveCoxes(res.data);
    });

    form.setFieldsValue({
      Captain: user.captain ? user.captain : "",
      ViceCaptain: user.vicecaptain ? user.vicecaptain : "",
      Coxes: user.coxes ? user.coxes : [],
    });
  }, []);
  const dependencies = [
    "Captain",
    "ViceCaptain",
    /*  "RowPast1",
    "RowPast2",
    "RowPast3", */
  ];
  const requiredFields = ["Captain" /* "ViceCaptain" */ /* "RowPast1" */];

  const onFinish = (values) => {
    const { Captain, ViceCaptain, Coxes, RowPast1, RowPast2, RowPast3 } =
      form.getFieldsValue();

    /* var rowpast = [];
    rowpast.push(RowPast1);
    if (RowPast2) {
      rowpast.push(RowPast2);
    }
    if (RowPast3) {
      rowpast.push(RowPast3);
    } */
    UpdateTeamRoles(Captain, ViceCaptain, Coxes /* , rowpast */).then((res) => {
      window.location.reload();
    });
  };

  function ProcessActiveCoxes(raceEntries) {
    var activecoxers = [];
    raceEntries.forEach((entry) => {
      if (entry.Class === "Water Race") {
        entry.Coxers.forEach((cox) => {
          activecoxers.push({
            id: cox._id,
            Name: cox.Name,
            Race: entry.RaceName,
          });
        });
      }
    });
    SetActiveCoxes(activecoxers);
  }

  return (
    <div>
      <Form
        form={form}
        size="large"
        onFieldsChange={() => {
          console.log(form.getFieldsValue());
        }}
        autoComplete={false}
        onFinish={onFinish}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 10 }}
      >
        <FormItemRow
          Label="Team Captain"
          name="Captain"
          required={true}
          dependencies={dependencies}
          options={rowers}
          disabled={user.stagescomplete.includes("Final Submission")}
        />
        <FormItemRow
          Label="Team Vice Captain"
          name="ViceCaptain"
          required={false}
          dependencies={dependencies}
          options={rowers}
          disabled={user.stagescomplete.includes("Final Submission")}
        />
        <Form.Item label={"Coxes"} name={"Coxes"}>
          <CoxesSelect user={user} rowers={rowers} activeCoxes={activeCoxes} />
        </Form.Item>

        {/*    <FormItemRow
          Label="Row Past"
          name="RowPast1"
          required={true}
          dependencies={dependencies}
          options={rowers}
        />
        <FormItemRow
          Label="Row Past"
          name="RowPast2"
          required={false}
          dependencies={dependencies}
          options={rowers}
        />
        <FormItemRow
          Label="Row Past"
          name="RowPast3"
          required={false}
          dependencies={dependencies}
          options={rowers}
        /> */}
        <Form.Item
          shouldUpdate={true}
          style={{ width: "100%", textAlign: "center" }}
        >
          {() => {
            // console.log(form.isFieldsTouched(["Declration"]))
            return (
              <Button
                size={"large"}
                block
                type="primary"
                htmlType="submit"
                disabled={
                  !requiredFieldsTouched(form, requiredFields) ||
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length ||
                  user.stagescomplete.includes("Final Submission")
                }
              >
                Save
              </Button>
            );
          }}
        </Form.Item>
        <Button
          size={"large"}
          type="danger"
          onClick={() => {
            window.location.reload();
          }}
        >
          Cancel Changes
        </Button>
      </Form>
    </div>
  );
};

function FormItemRow({
  Label,
  name,
  required,
  dependencies,
  options,
  disabled,
}) {
  return (
    <Form.Item
      label={Label}
      name={name}
      dependencies={dependencies}
      rules={[
        {
          required: required,
          message: "Please select a student",
        },
        ({ getFieldsValue, getFieldValue }) => ({
          validator(_, value) {
            // console.log(getFieldsValue());
            var isNameRepeated = IsNameRepeated(value, getFieldsValue);
            if (!value || !isNameRepeated) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(`Cannot repeat same person`));
          },
        }),
      ]}
    >
      <Select
        showSearch
        placeholder="Select a Student"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        allowClear
        disabled={disabled}
      >
        <OptGroup label="Under 25">
          {options
            ?.filter((r) => {
              return r.AgeGroup === "Under 25";
            })
            .map(function (item, i) {
              return <Option value={item._id}>{item.Name}</Option>;
            })}
        </OptGroup>
        <OptGroup label="Under 21">
          {options
            ?.filter((r) => {
              return r.AgeGroup === "Under 21";
            })
            .map(function (item, i) {
              return <Option value={item._id}>{item.Name}</Option>;
            })}
        </OptGroup>
        <OptGroup label="Under 18">
          {options
            ?.filter((r) => {
              return r.AgeGroup === "Under 18";
            })
            .map(function (item, i) {
              return <Option value={item._id}>{item.Name}</Option>;
            })}
        </OptGroup>
        <OptGroup label="Under 15">
          {options
            ?.filter((r) => {
              return r.AgeGroup === "Under 15";
            })
            .map(function (item, i) {
              return <Option value={item._id}>{item.Name}</Option>;
            })}
        </OptGroup>
      </Select>
    </Form.Item>
  );
}

function IsNameRepeated(name, getFieldsValue) {
  var keys = Object.keys(getFieldsValue());
  var count = 0;
  keys.forEach((key) => {
    if (getFieldsValue()[key] === name) {
      count++;
    }
  });
  if (count > 1) {
    // console.log("repeated");
    return true;
  } else {
    //  console.log("NOT repeated");
    return false;
  }
}
function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      console.log("returned false" + element);
      returnValue = false;
    }
  });
  return returnValue;
}

const CoxesSelect = ({ user, rowers, activeCoxes, value, onChange }) => {
  return (
    <Select
      mode="multiple"
      size="large"
      placeholder="Please select coxes"
      style={{ width: "100%" }}
      disabled={user.stagescomplete.includes("Final Submission")}
      showSearch
      filterOption={(input, option) => {
        console.log(option);
        return (
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      value={value}
      onChange={OnCoxChange}
    >
      {rowers.map((value, index) => {
        return <Option key={value._id}>{value.Name}</Option>;
      })}
    </Select>
  );
  function OnCoxChange(newCoxers) {
    var coxesDeleted = [];
    value.forEach((oldcox) => {
      if (!newCoxers.includes(oldcox)) {
        coxesDeleted.push(oldcox);
      }
    });

    var invalidDeletes = [];
    coxesDeleted.forEach((cox) => {
      if (activeCoxes.filter((e) => e.id === cox).length > 0) {
        invalidDeletes.push(activeCoxes.filter((e) => e.id === cox)[0].Name);
      }
    });

    if (invalidDeletes.length > 0) {
      confirm({
        title: `Cannot delete ${invalidDeletes[0]} as COX`,
        icon: <InfoCircleOutlined />,
        content: (
          <div>
            {invalidDeletes[0]} has already been assigned as a COX in a race(s).
            Please remove them as COX in all races before deleting.
          </div>
        ),
        okText: "Okay",
        okType: "primary",
        cancelButtonProps: { hidden: true },
        onOk() {},
      });
      return;
    } else {
      onChange(newCoxers);
    }
  }
};

export default TeamRoles;
