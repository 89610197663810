import React, { useState, useContext, useEffect } from "react";
import styles from "./EventEntries.module.scss";
import {
  getLoggedInUser,
  AddEventEntries as AddEventEntriesAPI,
} from "../../APIManager";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  Row,
  Col,
  message,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  U21MaleWR,
  U21MaleIR,
  U21FemaleWR,
  U21FemaleIR,
  U18MaleWR,
  U18MaleIR,
  U18FemaleWR,
  U18FemaleIR,
  U15MaleWR,
  U15MaleIR,
  U15FemaleWR,
  U15FemaleIR,
} from "../../utility";

import { ChromePicker } from "react-color";
const { TextArea } = Input;
const { confirm } = Modal;
const AddEventEntries = () => {
  const [errorMessage, SetErrorMessage] = useState(
    "Server Errror: Cannot reach server"
  );
  const [form] = Form.useForm();
  const [selectedEvents, SetSelectedEvents] = useState([]);

  function OnFormChange(formvalues) {
    var participatingevents = [];
    Object.keys(formvalues).forEach(function (key, index) {
      if (formvalues[key]) {
        participatingevents.push(key);
      }
    });
    SetSelectedEvents(participatingevents);
  }

  const onFinish = (values) => {
    console.log(form.getFieldsValue());
    var formvalues = form.getFieldsValue();
    var participatingevents = [];
    if (participatingevents.length < 1) {
      message.error("Choose atleast 1 event to save");
      return;
    }
    Object.keys(formvalues).forEach(function (key, index) {
      if (formvalues[key]) {
        participatingevents.push(key);
      }
    });
    console.log(participatingevents);
    confirm({
      title: "Confirm Submission",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to submit. Event entries cannot be edited/changed once submitted",
      okText: "Submit",
      okType: "primary",
      cancelText: "cancel",
      onOk() {
        console.log("OK");
        AddEventEntriesAPI(participatingevents).then(() => {
          window.location.reload();
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <div className={styles.MainDiv}>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        size="large"
        onChange={() => {
          // SetMessage(form.getFieldsValue());
          OnFormChange(form.getFieldsValue());
          //console.log(form.getFieldsError())
          // console.log(form.isFieldsTouched(["SchoolColor2"]));
        }}
      >
        <label>Enter yes for all events you intend to take part in</label>
        <br />
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <label className={styles.SubHeading}>
              Boys Under 21 Water Races
            </label>{" "}
            <SectionOptions optionsArray={U21MaleWR} category="Under 21 Boys" />
            <label className={styles.SubHeading}>
              Boys Under 18 Water Races
            </label>{" "}
            <SectionOptions optionsArray={U18MaleWR} category="Under 18 Boys" />
            <label className={styles.SubHeading}>
              Boys Under 15 Water Races
            </label>{" "}
            <SectionOptions optionsArray={U15MaleWR} category="Under 15 Boys" />
            <label className={styles.SubHeading}>
              Boys Under 21 Indoor Races
            </label>{" "}
            <SectionOptions optionsArray={U21MaleIR} category="Under 21 Boys" />
            <label className={styles.SubHeading}>
              Boys Under 18 Indoor Races
            </label>{" "}
            <SectionOptions optionsArray={U18MaleIR} category="Under 18 Boys" />
            <label className={styles.SubHeading}>
              Boys Under 15 Indoor Races
            </label>{" "}
            <SectionOptions optionsArray={U15MaleIR} category="Under 15 Boys" />
          </Col>
          <Col className="gutter-row" span={12}>
            <label className={styles.SubHeading}>
              Girls Under 21 Water Races
            </label>{" "}
            <SectionOptions
              optionsArray={U21FemaleWR}
              category="Under 21 Girls"
            />
            <label className={styles.SubHeading}>
              Girls Under 18 Water Races
            </label>{" "}
            <SectionOptions
              optionsArray={U18FemaleWR}
              category="Under 18 Girls"
            />
            <label className={styles.SubHeading}>
              Girls Under 15 Water Races
            </label>{" "}
            <SectionOptions
              optionsArray={U15FemaleWR}
              category="Under 15 Girls"
            />
            <label className={styles.SubHeading}>
              Girls Under 21 Indoor Races
            </label>{" "}
            <SectionOptions
              optionsArray={U21FemaleIR}
              category="Under 21 Girls"
            />
            <label className={styles.SubHeading}>
              Girls Under 18 Indoor Races
            </label>{" "}
            <SectionOptions
              optionsArray={U18FemaleIR}
              category="Under 18 Girls"
            />
            <label className={styles.SubHeading}>
              Girls Under 15 Indoor Races
            </label>{" "}
            <SectionOptions
              optionsArray={U15FemaleIR}
              category="Under 15 Girls"
            />
          </Col>
        </Row>
        <h1 className={styles.SubHeading}>Selected Events:</h1>
        <div>
          <ul>
            {selectedEvents.map((race) => {
              return <li>{race}</li>;
            })}
          </ul>
        </div>
        <Form.Item shouldUpdate={true}>
          {() => {
            // console.log(form.isFieldsTouched(["Declration"]))
            return (
              <Button
                size={"large"}
                block
                type="primary"
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                Submit
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
};

const SectionOptions = (props) => {
  const { optionsArray, category } = props;
  return optionsArray.map((race) => {
    return (
      <Form.Item
        label={race.Label}
        name={`${category} ${race.Label}`}
        rules={[
          {
            required: true,
            message: "Please seelect either 'Yes' or 'No'",
          },
        ]}
      >
        <Radio.Group>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
    );
  });
};

export default AddEventEntries;
