import React, { useState, useContext, useEffect } from "react";
import {
  GetRowers,
  UpdateTeamRoles,
  GetRaceEntries,
  UpdateRowerPhoneNumber,
} from "../../APIManager";
import { Form, Select, Button, Modal, Descriptions, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { UserContext } from "../../UserContext";
import styles from "./TeamRoles.module.css";
const { confirm } = Modal;
const { Option, OptGroup } = Select;
const TeamRoles = () => {
  const [user, setUser] = useContext(UserContext);
  const [rowers, SetRowers] = useState([]);
  const [activeCoxes, SetActiveCoxes] = useState([]);
  const [raceEntries, SetRaceEntries] = useState([]);

  const [fetchedData, SetFetchedData] = useState(false);

  const [editCoxModalOpen, SetEditCoxModalOpen] = useState(false);

  const [selectedCoxes, SetSelectedCoxes] = useState(
    user.coxes ? user.coxes : []
  );
  const [selectedCaptain, SetSelectedCaptain] = useState(
    user.captain ? user.captain : []
  );
  const [selectedViceCaptain, SetSelectedViceCaptain] = useState(
    user.vicecaptain ? user.vicecaptain : []
  );

  const [form] = Form.useForm();
  useEffect(() => {
    GetRowers().then((res) => {
      SetRowers(res.data);
      SetFetchedData(true);
    });

    GetRaceEntries().then((res) => {
      SetRaceEntries(res.data);

      ProcessActiveCoxes(res.data);
    });
  }, []);

  function ProcessActiveCoxes(raceEntries) {
    var activecoxers = [];
    raceEntries.forEach((entry) => {
      if (entry.Class === "Water Race") {
        entry.Coxers.forEach((cox) => {
          activecoxers.push({
            id: cox._id,
            Name: cox.Name,
            Race: entry.RaceName,
          });
        });
      }
    });
    SetActiveCoxes(activecoxers);
  }

  /* if (rowers.length < 1) {
    return "Loading"; //Replace with isfetched state or something simmilar
  } */

  if (!fetchedData) {
    return <>Loading</>;
  }

  var captain = selectedCaptain
    ? GetRowerFromID(selectedCaptain, rowers)
    : null;

  var vicecaptain = selectedViceCaptain
    ? GetRowerFromID(selectedViceCaptain, rowers)
    : null;

  return (
    <div>
      {editCoxModalOpen && (
        <CoxesSelectModal
          open={editCoxModalOpen}
          onClose={() => {
            SetEditCoxModalOpen(false);
          }}
          rowers={rowers}
          school={user}
          onSubmit={(e) => {
            UpdateTeamRoles(user.captain, user.vicecaptain, e).then(() => {
              window.location.reload();
            });
          }}
          activeCoxes={activeCoxes}
        />
      )}
      <h1 style={{ fontSize: "1.5em" }}>Team Roles</h1>
      <div style={{ maxWidth: "1200px" }}>
        {" "}
        <Descriptions bordered>
          <Descriptions.Item className={styles.Desc} label="Captain" span={2}>
            <CaptainNameField
              selectedRower={captain}
              rowers={rowers}
              school={user}
              onChange={(e) => {
                UpdateTeamRoles(e, user.vicecaptain, user.coxes).then(() => {
                  window.location.reload();
                });
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className={styles.Desc}
            label="Phone Number"
            span={1}
          >
            {captain && (
              <PhoneField
                selectedRower={captain}
                rowers={rowers}
                school={user}
                onChange={(e) => {
                  UpdateRowerPhoneNumber(captain._id, e).then((res) => {
                    window.location.reload();
                  });
                }}
              />
            )}
          </Descriptions.Item>
          <Descriptions.Item
            className={styles.Desc}
            label="Vice-Captain"
            span={2}
          >
            <CaptainNameField
              selectedRower={vicecaptain}
              rowers={rowers}
              school={user}
              onChange={(e) => {
                UpdateTeamRoles(user.captain, e, user.coxes).then(() => {
                  window.location.reload();
                });
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item
            className={styles.Desc}
            label="Phone Number"
            span={1}
          >
            {" "}
            {vicecaptain && (
              <PhoneField
                selectedRower={vicecaptain}
                rowers={rowers}
                school={user}
                onChange={(e) => {
                  UpdateRowerPhoneNumber(vicecaptain._id, e).then((res) => {
                    window.location.reload();
                  });
                }}
              />
            )}
          </Descriptions.Item>
          <Descriptions.Item className={styles.Desc} label="Coxwains" span={3}>
            <div style={{ width: "100%" }}>
              {user.coxes.map((c) => {
                return `${GetRowerFromID(c, rowers).Name}, `;
              })}{" "}
              <Button
                onClick={() => {
                  SetEditCoxModalOpen(true);
                }}
                disabled={user.stagescomplete.includes("Final Submission")}
              >
                Edit Coxes
              </Button>
            </div>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

const CoxesSelect = ({ user, rowers, activeCoxes, value, onChange }) => {
  return (
    <Select
      mode="multiple"
      size="large"
      placeholder="Please select coxes"
      style={{ width: "100%" }}
      disabled={user.stagescomplete.includes("Final Submission")}
      showSearch
      filterOption={(input, option) => {
        console.log(option);
        return (
          option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      value={value}
      onChange={OnCoxChange}
    >
      {rowers.map((value, index) => {
        return <Option key={value._id}>{value.Name}</Option>;
      })}
    </Select>
  );
  function OnCoxChange(newCoxers) {
    var coxesDeleted = [];
    value.forEach((oldcox) => {
      if (!newCoxers.includes(oldcox)) {
        coxesDeleted.push(oldcox);
      }
    });

    var invalidDeletes = [];
    coxesDeleted.forEach((cox) => {
      if (activeCoxes.filter((e) => e.id === cox).length > 0) {
        invalidDeletes.push(activeCoxes.filter((e) => e.id === cox)[0].Name);
      }
    });

    if (invalidDeletes.length > 0) {
      confirm({
        title: `Cannot delete ${invalidDeletes[0]} as COX`,
        icon: <InfoCircleOutlined />,
        content: (
          <div>
            {invalidDeletes[0]} has already been assigned as a COX in a race(s).
            Please remove them as COX in all races before deleting.
          </div>
        ),
        okText: "Okay",
        okType: "primary",
        cancelButtonProps: { hidden: true },
        onOk() {},
      });
      return;
    } else {
      onChange(newCoxers);
    }
  }
};

function GetRowerFromID(id, rowers) {
  console.log(id);
  console.log(rowers);
  return rowers.filter((r) => String(r._id) == String(id))[0];
}

export default TeamRoles;

const CaptainNameField = ({ selectedRower, rowers, school, onChange }) => {
  const [modalOpen, SetModalOpen] = useState(false);

  return (
    <>
      {modalOpen && (
        <NameSelectModal
          open={modalOpen}
          rowers={rowers}
          school={school}
          current={selectedRower}
          onClose={() => {
            SetModalOpen(false);
          }}
          onSubmit={(e) => {
            onChange(e);
          }}
        />
      )}
      {!selectedRower ? (
        <Button
          onClick={() => {
            SetModalOpen(true);
          }}
          disabled={school.stagescomplete.includes("Final Submission")}
        >
          Add Rower
        </Button>
      ) : (
        <>
          {" "}
          {selectedRower.Name}{" "}
          <Button
            onClick={() => {
              SetModalOpen(true);
            }}
            disabled={school.stagescomplete.includes("Final Submission")}
          >
            Change
          </Button>
        </>
      )}
    </>
  );
};

const PhoneField = ({ selectedRower, rowers, school, onChange }) => {
  const [modalOpen, SetModalOpen] = useState(false);

  return (
    <>
      {modalOpen && (
        <PhoneSelectModal
          open={modalOpen}
          rowers={rowers}
          school={school}
          current={selectedRower}
          onClose={() => {
            SetModalOpen(false);
          }}
          onSubmit={(e) => {
            onChange(e);
          }}
        />
      )}
      {!selectedRower.PhoneNumber ? (
        <Button
          onClick={() => {
            SetModalOpen(true);
          }}
          disabled={school.stagescomplete.includes("Final Submission")}
        >
          Add PhoneNumber
        </Button>
      ) : (
        <>
          {" "}
          {selectedRower.PhoneNumber}{" "}
          <Button
            onClick={() => {
              SetModalOpen(true);
            }}
            disabled={school.stagescomplete.includes("Final Submission")}
          >
            Change
          </Button>
        </>
      )}
    </>
  );
};

const NameSelectModal = ({
  open,
  onClose,
  rowers,
  school,
  onSubmit,
  current,
}) => {
  const rowersList = rowers.filter(
    (r) => r._id != school.captain && r._id != school.vicecaptain
  );

  const [selectedRower, SetSelectedRower] = useState();

  return (
    <Modal
      title="Change"
      forceRender
      visible={open}
      onCancel={onClose}
      onOk={() => {
        onSubmit(selectedRower);
      }}
      okText="Submit"
      cancelText="cancel"
      width="500px"
      okButtonProps={{
        disabled: selectedRower ? false : true,
      }}
      maskClosable={false}
    >
      {current ? `Currently Selected: ${current.Name}` : ""}
      <Select
        showSearch
        placeholder="Select a rower"
        optionFilterProp="children"
        allowClear
        style={{ width: "100%" }}
        onChange={(e) => {
          SetSelectedRower(e);
          console.log(e);
        }}
      >
        {rowersList.map(function (item, i) {
          return <Option value={item._id}>{item.Name}</Option>;
        })}
      </Select>
    </Modal>
  );
};

const PhoneSelectModal = ({
  open,
  onClose,
  rowers,
  school,
  onSubmit,
  current,
}) => {
  const [phone, SetPhone] = useState(current.PhoneNumber);

  return (
    <Modal
      title="Change"
      forceRender
      visible={open}
      onCancel={onClose}
      onOk={() => {
        onSubmit(phone);
      }}
      okText="Submit"
      cancelText="cancel"
      width="500px"
      okButtonProps={{
        disabled: phone ? false : true,
      }}
      maskClosable={false}
    >
      <Input
        value={phone}
        onChange={(e) => {
          SetPhone(e.target.value);
        }}
      ></Input>
    </Modal>
  );
};

const CoxesSelectModal = ({
  open,
  onClose,
  rowers,
  school,
  onSubmit,
  activeCoxes,
}) => {
  const [selectedCoxes, SetSelectedCoxes] = useState(school.coxes);

  return (
    <Modal
      title="Change"
      forceRender
      visible={open}
      onCancel={onClose}
      onOk={() => {
        onSubmit(selectedCoxes);
      }}
      okText="Submit"
      cancelText="cancel"
      width="500px"
      okButtonProps={{
        disabled: selectedCoxes.length > 0 ? false : true,
      }}
      maskClosable={false}
    >
      <div style={{ width: "100%" }}>
        <CoxesSelect
          user={school}
          rowers={rowers}
          activeCoxes={activeCoxes}
          value={selectedCoxes}
          onChange={(e) => {
            SetSelectedCoxes(e);
          }}
        />
      </div>
    </Modal>
  );
};
