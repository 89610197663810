import React, { useContext, useState, useEffect } from "react";
import styles from "./EventInstructions.module.css";
import { stringConstants } from "../../constants";
import { GetCMSDocument } from "../../APIManager";

import { ConfigContext } from "../../ConfigContext";

export default function EventInstructions() {
  let [config, setConfig] = useContext(ConfigContext);
  const [cmsEventInstructions, SetCMSEventInstructions] = useState("");

  useEffect(() => {
    GetCMSDocument("EventInstructionsPageInstructions").then((res) => {
      SetCMSEventInstructions(res?.data?.Data?.Instructions);
    });
  }, []);

  function ReplaceVariables(template) {
    return template.replace(
      /{stringConstants\.([\w]+)}/g, // Regex to match {stringConstants.PropertyName}
      (_, key) => config.cpConfig[key] || `{stringConstants.${key}}` // Replace with value or keep the placeholder if not found
    );
  }

  return (
    <div className={styles.MainContainer}>
      <div
        dangerouslySetInnerHTML={{
          __html: ` ${ReplaceVariables(cmsEventInstructions)}`,
        }}
      />
    </div>
  );

  return (
    <div className={styles.MainContainer}>
      <div>
        <h1>
          <strong>Rules for Event Registration</strong>
        </h1>
        <ol>
          <li>Each school is allowed one entry in each event</li>
          <li>
            All participants must be full time students at their registered
            schools{" "}
          </li>
          <li>
            A rower may be registered for a maximum of 2 indoor events and 2
            events on the water as a rower, including exhibition events{" "}
            <ol type="a">
              <li>
                A participant will be allowed to enter a third water event as a
                cox
              </li>
              <li>
                Please note that races will NOT be delayed to wait for a rower
                or cox who is on the water for another race
              </li>
            </ol>
          </li>
          <li>
            Rowers:
            <ol type="a">
              <li>
                Must be entered in events in the correct category for their age
                and gender. Entry of younger rowers into older age category
                races is also permitted
              </li>
              <li>Must be at least novice level</li>
              <li>
                Must have competed as a rower in at least one Club, National or
                International Regatta (excluding intra-school regattas)
              </li>
              <li>
                Participating only in indoor rowing do not have to be Rowing
                Members at KBC{" "}
              </li>
            </ol>
          </li>
          <li>
            Coxwains:
            <ol type="a">
              <li>
                Age categories for rowers also apply to coxes. Gender categories
                do NOT apply. Girls may cox in boys’ races and vice versa.
              </li>
              <li>
                Must have attended a Seminar for Coxswains and passed the Cox
                test.
              </li>
              <li>
                Must have competed as a cox in at least one Club, National or
                International Regatta (excluding intra-school regattas)
              </li>
              <li>
                Minimum weight 50kg for both girls’ and boys’ events. May not
                weigh less than 35kg. Sandbags must be carried by coxes whose
                weight is 35-49 kg.
              </li>
            </ol>
          </li>
          <li>
            Substitutes:
            <ol type="a">
              <li>
                It is strongly recommended to list substitute
                rower(s)/coxswain(s) for all events
              </li>
              <li>The use of listed substitutes is not required</li>
              <li>Unlisted substitutes will not be allowed to race</li>
              <li>
                Substitute is allowed for 1x – single scull (as per 2021 ISR
                AGM)
              </li>
            </ol>
          </li>
          <li>
            Schools are not eligible to participate in the Under 25 category.
            Universities are not eligible to participate in Under 21, Under 18
            and Under 15 categories
          </li>
        </ol>
        <br />
        <h1>
          <strong>Age Categories and Event Entry</strong>
        </h1>
        <div className={styles.MyTable}>
          <table>
            <tr>
              <th>Category</th>
              <th>By age student will turn in {stringConstants.Year}</th>
              <th>By student’s date of birth</th>
              <th>Permitted categories for event entry</th>
            </tr>
            <tr>
              <td>Under 25</td>
              <td>24 at the oldest</td>
              <td>{stringConstants.U25AllowedYears}</td>
              <td>Under 25</td>
            </tr>
            <tr>
              <td>Under 21</td>
              <td>20 at the oldest</td>
              <td>{stringConstants.U21AllowedYears}</td>
              <td>Under 21</td>
            </tr>
            <tr>
              <td>Under 18</td>
              <td>17 at the oldest</td>
              <td>{stringConstants.U18AllowedYears}</td>
              <td>Under 21 and Under 18</td>
            </tr>
            <tr>
              <td>Under 15</td>
              <td>14 at the oldest</td>
              <td>{stringConstants.U15AllowedYears}</td>
              <td>Under 21, Under 18 and Under 15</td>
            </tr>
          </table>
        </div>
        <br />
        <h1>
          <strong>Note:</strong>
        </h1>
        <ol>
          <li>
            Please carefully proofread entries, as changes will not be
            permitted. Especially check:
          </li>
          <ol type="a">
            <li>
              Rowers' positions in the boat (water)/order of rowing (indoor){" "}
            </li>
            <li>
              Names: Must exactly match the ID document (correctly composed and
              spelled)
            </li>
            <strong>
              KBC cannot make any changes or corrections to data entered by
              schools.
            </strong>
          </ol>
          <li>
            KBC will not be responsible for certificates issued in the wrong
            name due to wrong entry. Availability of a replacement certificate
            cannot be guaranteed.
          </li>
        </ol>
      </div>
      {/*       <div
        dangerouslySetInnerHTML={{
          __html: `<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><strong>Rules for event registration </strong></span></span></p>

<ol>
	<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Each school is allowed one entry in each event&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></li>
	<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">All participants must be full time students at their registered schools&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></li>
	<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">A rower may be registered for a <em>maximum</em> of 2 indoor events and 2 events on the water as a rower, including exhibition events &nbsp;&nbsp;</span></span></span></li>
<ol style="list-style-type:lower-alpha">
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">A participant will be allowed to enter a third water event as a cox </span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Please note that races will NOT be delayed to wait for a rower or cox who is on the water for another race </span></span></span></li>
	</ol>
  </ol>
<ol start="3">
	<li style="list-style-type:none">
	</li>
	<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Rowers: </span></span></span>
	<ol style="list-style-type:lower-alpha">
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Must be entered in events in the correct category for their age and gender. Entry of younger rowers into older age category races is also permitted</span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Must be at least novice level</span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Must have competed as a rower in at least one Club, National or International Regatta (excluding intra-school regattas)</span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Participating only in indoor rowing do not have to be Rowing Members at KBC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></li>
	</ol>
	</li>
	<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Coxswains: </span></span></span>
	<ol style="list-style-type:lower-alpha">
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Age categories for rowers also apply to coxes. Gender categories do NOT apply. Girls may cox in boys’ races and vice versa.</span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Must have attended a Seminar for Coxswains and passed the Cox test.</span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Must have competed as a cox in at least one Club, National or International Regatta (excluding intra-school regattas) </span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Minimum weight 50kg for both girls’ and boys’ events. May not weigh less than 35kg. Sandbags must be carried by coxes whose weight is 35-49 kg.</span></span></span>
		</li>
	</ol>
	</li>
	<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Substitutes:</span></span></span>
	<ol style="list-style-type:lower-alpha">
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">It is strongly recommended to list substitute rower(s)/coxswain(s) for all events </span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">The use of listed substitutes is not required </span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Unlisted substitutes will not be allowed to race&nbsp; </span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Substitute is allowed for 1x &ndash; single scull&nbsp; (as per 2021 ISR AGM) </span></span></span></li>
	</ol>
	</li>
	<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Schools are not eligible to participate in the Under 25 category. Universities are not eligible to participate in Under 21, Under 18 and Under 15 categories</span></span></span>
</ol>

<p>&nbsp;</p>

<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><strong>Age Categories and Event Entry</strong></span></span></p>

<table cellspacing="0" class="Table" style="border-collapse:collapse; margin-left:40px; width:567px">
	<tbody>
		<tr>
			<td style="background-color:#deeaf6; border-bottom:1px solid black; border-left:1px solid black; border-right:none; border-top:1px solid black; height:20px; width:76px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><strong><span style="font-size:11.0pt">Category</span></strong></span></span></p>
			</td>
			<td style="background-color:#deeaf6; border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:1px solid black; height:20px; width:123px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><strong><span style="font-size:11.0pt"><span style="color:black">By age student will turn in 2022</span></span></strong></span></span></p>
			</td>
			<td style="background-color:#deeaf6; border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:1px solid black; height:20px; width:170px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><strong><span style="font-size:11.0pt"><span style="color:black">By student&rsquo;s date of birth</span></span></strong></span></span></p>
			</td>
			<td style="background-color:#deeaf6; border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:1px solid black; height:20px; vertical-align:top; width:198px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><strong><span style="font-size:11.0pt"><span style="color:black">Permitted categories for event entry</span></span></strong></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#deeaf6; border-bottom:1px solid black; border-left:1px solid black; border-right:none; border-top:none; height:20px; width:76px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt"><span style="color:black">Under 25</span></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; height:20px; width:123px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">24 at the oldest</span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; height:20px; width:170px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Born in 1999 or after </span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; height:20px; vertical-align:top; width:198px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Under 25</span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#deeaf6; border-bottom:1px solid black; border-left:1px solid black; border-right:none; border-top:none; height:20px; width:76px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt"><span style="color:black">Under 21</span></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; height:20px; width:123px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">20 at the oldest</span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; height:20px; width:170px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Born in 2003, 2004, 2005</span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; height:20px; vertical-align:top; width:198px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Under 21</span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#deeaf6; border-bottom:1px solid black; border-left:1px solid black; border-right:none; border-top:none; height:21px; width:76px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt"><span style="color:black">Under 18</span></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; height:21px; width:123px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">17 at the oldest</span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; height:21px; width:170px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Born in 2006, 2007, 2008</span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; height:21px; vertical-align:top; width:198px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Under 18, Under 21</span></span></span></p>
			</td>
		</tr>
		<tr>
			<td style="background-color:#deeaf6; border-bottom:1px solid black; border-left:1px solid black; border-right:none; border-top:none; height:21px; width:76px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt"><span style="color:black">Under 15</span></span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; height:21px; width:123px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">14 at the oldest</span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; height:21px; width:170px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Born in 2009 or after</span></span></span></p>
			</td>
			<td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; height:21px; vertical-align:top; width:198px">
			<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Under 15, Under 18, Under 21</span></span></span></p>
			</td>
		</tr>
	</tbody>
</table>

<p>&nbsp;</p>

<p><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><strong>Notices&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </strong></span></span></p>

<ol>
	<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Please carefully proofread entries, as changes will not be permitted. Especially check:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span>

	<ol>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Rowers&#39; positions in the boat (water)/order of rowing (indoor)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; .&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span></li>
		<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt">Names:&nbsp; Must exactly match the ID document (correctly composed and spelled)</span></span></span></li>
	</ol>
	</li>
</ol>

<p style="margin-left:96px"><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:12pt"><span style="font-size:11.0pt"><strong>KBC cannot make any changes or corrections to data entered by schools.</strong></span></span></span></p>

<ol start="2">
	<li><span style="font-family:Arial,Helvetica,sans-serif"><span style="font-size:11pt">KBC will not be responsible for certificates issued in the wrong name due to wrong entry. Availability of a replacement certificate cannot be guaranteed.</span></span></li>
</ol>
`,
        }}
      ></div> */}
    </div>
  );
}
