import React, { useState, useContext, useEffect } from "react";
import { useLocation, useHistory, bro } from "react-router-dom";
import { Layout, Menu, Modal } from "antd";
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  TeamOutlined,
  UserOutlined,
  UploadOutlined,
  HomeOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  DollarCircleOutlined,
  PartitionOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import styles from "./MainLayout.module.css";
import { ConfigContext } from "../../ConfigContext";
import { stringConstants } from "../../constants";
import { UserContext } from "../../UserContext";

const { Sider } = Layout;
const { confirm } = Modal;
const SideBar = () => {
  const location = useLocation();
  const history = useHistory();
  const selectedKey = GetSelectedKey();
  let [config, SetConfig] = useContext(ConfigContext);
  const [user, SetUser] = useContext(UserContext);

  var backListener;
  const [locationKeys, setLocationKeys] = useState([]);
  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
        }
      }
    });
  }, [locationKeys]);
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
      title="Topics"
    >
      <div className="logo" />
      <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
        <Menu.ItemGroup
          title={`ISR ${stringConstants.Year} Registrations`}
        ></Menu.ItemGroup>
        <Menu.Item
          key="1"
          icon={<HomeOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/");
          }}
        >
          Home
        </Menu.Item>
        <Menu.Item
          key="Instructions"
          icon={<HomeOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/Instructions");
          }}
        >
          Instructions
        </Menu.Item>
        <Menu.Item
          key="Event Entries"
          icon={<UploadOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/evententries");
          }}
        >
          Event Entries
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<UserOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/schoolinfo");
          }}
        >
          School Info
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<TeamOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/studentregistrations");
          }}
        >
          Team Registration
        </Menu.Item>
        <Menu.Item
          key="StudentFileUploads"
          icon={<TeamOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/studentfileuploads");
          }}
        >
          File Uploads
        </Menu.Item>
        <Menu.Item
          key="teamroles"
          icon={<IdcardOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/TeamRoles");
          }}
        >
          Team Roles
        </Menu.Item>
        <Menu.Item
          key="eventInstructions"
          icon={<HomeOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/eventInstructions");
          }}
          style={{ height: "70px", lineHeight: "20px" }}
        >
          Event Registration
          <br />
          Rules
        </Menu.Item>
        <Menu.Item
          key="5"
          icon={<PartitionOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/teamregistrations");
          }}
        >
          Event Registrations
        </Menu.Item>
        <Menu.Item
          key="Payment"
          icon={<DollarCircleOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/payment");
          }}
          hidden={user.otherattr?.includes("Payment Waived")}
        >
          Payment
        </Menu.Item>
        <Menu.Item
          key="submission"
          icon={<FileDoneOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/submission");
          }}
        >
          Submission
        </Menu.Item>
      </Menu>
    </Sider>
  );
  function GetSelectedKey() {
    var path = location.pathname;
    switch (path) {
      case "/":
        return "1";
      case "/schoolinfo":
        return "2";
      case "/evententries":
        return "Event Entries";
      case "/studentregistrations":
        return "4";
      case "/TeamRoles":
        return "teamroles";
      case "/teamregistrations":
        return "5";
      case "/studentfileuploads":
        return "StudentFileUploads";
      case "/payment":
        return "Payment";
      case "/submission":
        return "submission";
      case "/Instructions":
        return "Instructions";
      case "/eventInstructions":
        return "eventInstructions";
      default:
        break;
    }
  }

  function OnMenuButtonClicked(path) {
    if (path === history.location) {
      return;
    }
    if (config.showUnsavedProgress) {
      confirm({
        title: "Confirm",
        icon: <ExclamationCircleOutlined />,
        content: "You may have unsaved progress. Are you sure you want to quit",
        okText: "Yes",
        okType: "primary",
        cancelText: "cancel",
        onOk() {
          history.push(path);
        },
        onCancel() {},
      });
    } else {
      history.push(path);
    }
  }
};

export default SideBar;
