import React, { useState, useContext, useEffect } from "react";
import { Prompt } from "react-router";
import styles from "./SchoolInfoPage.module.css";
import { getLoggedInUser, AddSchoolInfo } from "../../APIManager";
import { UserContext } from "../../UserContext";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  message,
  Select,
} from "antd";
import ImgCrop from "antd-img-crop";
import { ChromePicker } from "react-color";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ConfigContext } from "../../ConfigContext";
const { confirm } = Modal;
const { TextArea } = Input;
const AddSchoolInfoPage = () => {
  const [user, setUser] = useContext(UserContext);
  let [config, setConfig] = useContext(ConfigContext);
  const [showErrorMessage, SetShowErrorMessage] = useState(false);
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);
  const [form] = Form.useForm();
  const contactTypes = ["Primary", "Emergency", "None"];
  const requiredFields = [
    "SchoolAddress",
    "SchoolEmailAddress",
    "SchoolWebsite",
    /*   "SchoolColor1", */
    "PrincipalFullName",
    "PrincipalTitle",
    "PrincipalEmailAddress",
    "PrincipalPhoneNumber",
    "PrincipalContactType",
    "RowingCoordinatorFullName",
    "RowingCoordinatorTitle",
    "RowingCoordinatorEmailAddress",
    "RowingCoordinatorPhoneNumber",
    "RowingCoordinatorContactType",
    "CoachFullName",
    "CoachEmailAddress",
    "CoachPhoneNumber",
    "AGMName",
    "SchoolDeclaration",
    "SchoolLogo",
  ];

  const onFinish = (values) => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content:
        "Confirm you want to save. You will be allowed to edit these later on",
      okText: "Save",
      okType: "primary",
      cancelText: "Back",
      onOk() {
        SetWaitingOnResponse(true);
        AddSchoolInfo(form.getFieldsValue())
          .then((res) => {
            SetWaitingOnResponse(false);
            window.location.reload();
          })
          .catch(() => {
            SetShowErrorMessage(true);
            message.warning(
              "A Server/Network Error Occured, please check your connection"
            );
            SetWaitingOnResponse(false);
          });
      },
      onCancel() {},
    });
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const ColorBar = ({ color }) => {
    return (
      <span
        style={{
          height: "20px",
          width: "5em",
          backgroundColor: color,
          borderWidth: "2px",
          borderStyle: "solid",
        }}
        hidden={!color}
      >
        {color}
      </span>
    );
  };
  return (
    <div className={styles.MainDiv}>
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            // console.log("Backing up...");
          }

          return location.pathname.startsWith("/app")
            ? true
            : `Are you sure you want to go to ${location.pathname}?`;
        }}
      />
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout={"vertical"}
        size="large"
        onChange={() => {
          // SetMessage(form.getFieldsValue());
          //console.log(form.getFieldsValue());
          //console.log(form.getFieldsError())
          // console.log(form.isFieldsTouched(["SchoolColor2"]));
        }}
      >
        <div style={{ fontSize: "1.2em" }}>
          School Name : {user.schoolname}
          <br />
          School Initials : {user.schoolinitials}
          <br />
          School Color 1 : <ColorBar color={user.rowingcolors[0]} />
          <br />
          School Color 2 : <ColorBar color={user.rowingcolors[1]} />
          <br />
          School Color 3 : <ColorBar color={user.rowingcolors[2]} />
          <br />
          <br />
        </div>
        <Form.Item
          label="School Address"
          name="SchoolAddress"
          rules={[
            { required: true, message: "Please input your School Address" },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="School Website"
          name="SchoolWebsite"
          rules={[
            { required: true, message: "Please input your School Website" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="School Email Address"
          name="SchoolEmailAddress"
          rules={[
            {
              required: true,
              message: "Please input your School Email Address",
            },
            { type: "email", message: "Please enter a valid Email Address" },
          ]}
        >
          <Input />
        </Form.Item>
        {/*   <Form.Item label="School Colors" style={{ marginBottom: 0 }}>
          <Form.Item
            label="School Color 1"
            name="SchoolColor1"
            defaultValue="#4r4r4r"
            rules={[{ required: true }]}
            style={{ display: "inline-block" }}
          >
            <ColorPicker showIgnore={false} />
          </Form.Item>
          <Form.Item
            label="School Color 2"
            name="SchoolColor2"
            rules={[{ required: false }]}
            style={{
              display: "inline-block",
              margin: "0 8px",
            }}
          >
            <ColorPicker showIgnore={true} />
          </Form.Item>
          <Form.Item
            label="School Color 3"
            name="SchoolColor3"
            rules={[{ required: false }]}
            style={{
              display: "inline-block",
              margin: "0 8px",
            }}
          >
            <ColorPicker showIgnore={true} />
          </Form.Item>
        </Form.Item> */}
        <Divider />
        <Form.Item label="Principal Info" style={{ marginBottom: 0 }}>
          <Form.Item
            label="Principal Full Name"
            name="PrincipalFullName"
            rules={[
              {
                required: true,
                message: "Please input your Principal's Full Name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Principal Title"
            name="PrincipalTitle"
            rules={[
              {
                required: true,
                message: "Please input your Principal Title",
              },
            ]}
          >
            <Select
              showSearch
              options={["Mr", "Ms", "Mrs", "Miss", "Prof", "Dr"].map((e) => {
                return { value: e, label: e };
              })}
            />
          </Form.Item>
          <Form.Item
            label="Principal Email Address"
            name="PrincipalEmailAddress"
            rules={[
              {
                required: true,
                message: "Please input your Principal Email Address",
              },
              { type: "email", message: "Please enter a valid Email Address" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Principal Phone Number"
            name="PrincipalPhoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your Principal Phone Number",
              },
              /*          ({ getFieldValue }) => ({
                validator(_, value) {
                  var mobileRegex = new RegExp(/^03[0-9]{2}[0-9]{7}$/);
                  var landlineRegex = new RegExp(/^021[0-9]{8}$/);
                  if (
                    !value ||
                    mobileRegex.test(value) ||
                    landlineRegex.test(value)
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `Please enter a valid number in the format: 03001234567 or 02131234567`
                    )
                  );
                },
              }), */
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Principal Contact Type"
            name="PrincipalContactType"
            dependencies={["RowingCoordinatorContactType"]}
            rules={[
              {
                required: true,
                message: "Please select Contact Type for Principal",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    !(
                      value?.includes("None") &&
                      (value?.includes("Emergency") ||
                        value?.includes("Primary"))
                    )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `Cant be None and Emergency/Primary at the same time`
                    )
                  );
                },
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  var RowingCoordinatorContactType = getFieldValue(
                    "RowingCoordinatorContactType"
                  );
                  if (
                    !value ||
                    !(
                      RowingCoordinatorContactType?.includes("None") &&
                      RowingCoordinatorContactType.length < 2 &&
                      value?.includes("None") &&
                      value.length < 2
                    )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `Must be atleast one Primary and Emergency Contact`
                    )
                  );
                },
              }),
            ]}
          >
            <Checkbox.Group options={contactTypes} defaultValue={["Primary"]} />
          </Form.Item>
        </Form.Item>
        <Divider />
        <Form.Item label="Rowing Coordinator Info" style={{ marginBottom: 0 }}>
          <Form.Item
            label="Rowing Coordinator Full Name"
            name="RowingCoordinatorFullName"
            rules={[
              {
                required: true,
                message: "Please input your Rowing Coordinator Full Name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Rowing Coordinator Title"
            name="RowingCoordinatorTitle"
            rules={[
              {
                required: true,
                message: "Please input your Rowing Coordinator Title",
              },
            ]}
          >
            <Select
              showSearch
              options={["Mr", "Ms", "Mrs", "Miss", "Prof", "Dr"].map((e) => {
                return { value: e, label: e };
              })}
            />
          </Form.Item>
          <Form.Item
            label="Rowing Coordinator Email Address"
            name="RowingCoordinatorEmailAddress"
            rules={[
              {
                required: true,
                message: "Please input your Rowing Coordinator Email Address",
              },
              { type: "email", message: "Please enter a valid Email Address" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Rowing Coordinator Phone Number"
            name="RowingCoordinatorPhoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your Roowing Coordinator Phone Number",
              },
              /*  {
                pattern: new RegExp(/^03[0-9]{2}[0-9]{7}$/),
                message:
                  "Please enter a valid number in the format: 03001234567",
              }, */
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Rowing Coordinator Contact Type"
            name="RowingCoordinatorContactType"
            dependencies={["PrincipalContactType"]}
            rules={[
              {
                required: true,
                message: "Please select Contact Type for Rowing Coordinator",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    !value ||
                    !(
                      value?.includes("None") &&
                      (value?.includes("Emergency") ||
                        value?.includes("Primary"))
                    )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `Cant be None and Emergency/Primary at the same time`
                    )
                  );
                },
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  var PrincipalContactType = getFieldValue(
                    "PrincipalContactType"
                  );
                  if (
                    !value ||
                    !(
                      PrincipalContactType?.includes("None") &&
                      PrincipalContactType.length < 2 &&
                      value?.includes("None") &&
                      value.length < 2
                    )
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `Must be atleast one Primary and Emergency Contact`
                    )
                  );
                },
              }),
            ]}
          >
            <Checkbox.Group options={contactTypes} defaultValue={["Primary"]} />
          </Form.Item>
        </Form.Item>
        <Divider />
        <Form.Item label="Coach Info" style={{ marginBottom: 0 }}>
          <Form.Item
            label="Coach Full Name"
            name="CoachFullName"
            rules={[
              {
                required: true,
                message: "Please input your Coach Full Name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Coach Email Address"
            name="CoachEmailAddress"
            rules={[
              {
                required: true,
                message: "Please input your Coach Email Address",
              },
              { type: "email", message: "Please enter a valid Email Address" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Coach Phone Number"
            name="CoachPhoneNumber"
            rules={[
              {
                required: true,
                message: "Please input your Coach Phone Number",
              },
              /*  {
                pattern: new RegExp(/^03[0-9]{2}[0-9]{7}$/),

                message:
                  "Please enter a valid number in the format: 03001234567",
              }, */
            ]}
          >
            <Input />
          </Form.Item>
        </Form.Item>
        <Divider />
        <Form.Item
          label="Name of person authorized to vote in AGM"
          name="AGMName"
          rules={[
            {
              required: true,
              message: "Please Name of person authorized to vote in AGM",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={`Nomination for Best Coxswain of ${
            config.cpConfig.EventYear - 1
          }`}
          name="COXNomination"
          rules={[
            {
              required: false,
              message: `Please Nomination for Best Coxswain of ${
                config.cpConfig.EventYear - 1
              }`,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Please justify the above nomination"
          name="COXNominationJustification"
          rules={[
            {
              required: false,
              message: `Please Nomination for Best Coxswain of ${
                config.cpConfig.EventYear - 1
              }`,
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label="Upload School declaration (signed and stamped). School declaration may be printed from the home page."
          name="SchoolDeclaration"
          rules={[
            {
              required: true,
              message: "Please upload school declaration",
            },
          ]}
        >
          <FileUpload />
        </Form.Item>
        <Form.Item
          label="Upload School Logo (.png .jpeg or .jpg) (min 300dpi)"
          name="SchoolLogo"
          rules={[
            {
              required: true,
              message: "Please upload School Logo",
            },
          ]}
        >
          <LogoFileUpload />
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => {
            // console.log(form.isFieldsTouched(["Declration"]))
            return (
              <Button
                size={"large"}
                block
                type="primary"
                htmlType="submit"
                disabled={
                  !requiredFieldsTouched(form, requiredFields) ||
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                Save
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddSchoolInfoPage;

function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      // console.log("returned false" + element);
      returnValue = false;
    }
  });
  return returnValue;
}

const ColorPicker = ({ value, onChange, showIgnore }) => {
  if (value === undefined) {
    value = { color: "#000000", ignore: showIgnore };
  }
  return (
    <div>
      <ChromePicker
        color={value.color}
        onChange={(color) => {
          if (value.ignore) {
            //   return; This can be unccomented to prevent changing of color while ignore is true
          }
          onChange({ color: color.hex, ignore: value.ignore });
        }}
      />
      <div style={{ backgroundColor: value.color, height: "30px" }}></div>
      {showIgnore ? (
        <Checkbox
          checked={value.ignore}
          onChange={(e) =>
            onChange({ color: value.color, ignore: e.target.checked })
          }
        >
          Ignore Color
        </Checkbox>
      ) : (
        <></>
      )}
    </div>
  );
};

const FileUpload = ({ value, onChange, onUpload }) => {
  return (
    <Upload
      maxCount={1}
      accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      onRemove={() => {
        onChange("");
      }}
      onChange={() => {}}
      beforeUpload={(file) => {
        let reader = new FileReader();
        reader.addEventListener("load", function (e) {
          let text = e.target.result;
          onChange(text);
        });
        reader.readAsDataURL(file);

        // Prevent upload
        return false;
      }}
    >
      <Button>Select File</Button>
    </Upload>
  );
};

const LogoFileUpload = ({ value, onChange, onUpload }) => {
  return (
    <ImgCrop
      rotate
      aspect={1 / 1}
      minZoom={0.1}
      cropperProps={{ restrictPosition: false }}
    >
      <Upload
        maxCount={1}
        accept=".png,.jpg,.jpeg"
        listType="picture-card"
        onRemove={() => {
          onChange("");
        }}
        onChange={() => {}}
        beforeUpload={(file) => {
          let reader = new FileReader();
          reader.addEventListener("load", function (e) {
            let text = e.target.result;
            onChange(text);
          });
          reader.readAsDataURL(file);
          //Dont Prevent upload to avoid thumbnail bug hence returning true
          return true;
        }}
      >
        <Button>Select File</Button>
      </Upload>
    </ImgCrop>
  );
};
