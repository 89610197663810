import React from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "./UserContext";
import { useContext } from "react";
import { getLoggedInUser } from "./APIManager";
import { isEqual } from "lodash";

export const ProtectedRoute = ({ Component: Component, ...rest }) => {
  const [user, setUser] = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        ReCheckAuth();
        if (user.isloggedin) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  function ReCheckAuth() {
    getLoggedInUser().then((res) => {
      if (!isEqual(user, res.data)) {
        setUser(res.data);
      }
    });
  }
};
