import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../UserContext";
import AddEventEntries from "./AddEventEntries";
import ViewEventEntries from "./ViewEventEntries";

import { SplitEntriesByCategory } from "../../utility";

const EventEntries = () => {
  const [user, setUser] = useContext(UserContext);
  const [editMode, SetEditMode] = useState(false);

  return <ViewEventEntries />;
};

export default EventEntries;
