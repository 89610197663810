import { React, useContext } from "react";
import { Table, Button, Modal, Input, message } from "antd";
import { UserContext } from "../../UserContext";
import UploadedFile from "../Shared Components/UploadedFile";
import { GetRower, GetStudentEvents, UpdateRower } from "../../APIManager";

import ReadOnlyUploadedFile from "../Shared Components/ReadOnlyUploadedFile";

import { useHistory } from "react-router";
import { useState, useEffect } from "react";

export default function ViewStudentInformation({
  rowerid,
  modalVisible,
  closeModal,
  onSubmit
}) {
  const history = useHistory();
  const [user, setUser] = useContext(UserContext);
  const [rower, SetRower] = useState({});
  const [evententries, SetEvententries] = useState([]);
  const [originalRower, setOriginalRower] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    SetRower({});
    SetEvententries([]);
    GetRower(rowerid).then((res) => {
      SetRower(res.data);
      setOriginalRower(res.data);
    });

    GetStudentEvents(rowerid).then((res) => {
      SetEvententries(res.data.Entries);
    });
  }, [rowerid]);

  const handleEdit = (key, value) => {
    const updatedRower = { ...rower, [key]: value };
    SetRower(updatedRower);
  };

  const handleSave = () => {
    // Validate required fields
    if (!rower.Name || rower.Name.trim() === "") {
      message.error("Name cannot be empty.");
      return;
    }

    // Validate email format if provided
    if (
      rower.EmailAddress &&
      !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(rower.EmailAddress)
    ) {
      message.error("Invalid email address.");
      return;
    }

    UpdateRower(rowerid, rower.Name, rower.EmailAddress, rower.PhoneNumber)
      .then(() => {
        message.success("Rower information updated successfully.");
        setOriginalRower(rower);
        setIsEditing(false);
        onSubmit()
      })
      .catch((err) => {
        console.error("Failed to update rower info:", err);
        message.error("Failed to update information. Please try again.");
      });
  };

  const handleCancelEdit = () => {
    SetRower(originalRower);
    setIsEditing(false);
  };

  const dataSource = GetDataSource(rower);

  const columns = [
    {
      dataIndex: "Key",
      key: "Key",
    },
    {
      dataIndex: "Value",
      key: "Value",
      render: (text, record) => {
        if (
          (record.Key === "Name" ||
            record.Key === "EmailAddress" ||
            record.Key === "PhoneNumber") &&
          isEditing
        ) {
          return (
            <Input
              value={text}
              onChange={(e) => handleEdit(record.Key, e.target.value)}
            />
          );
        }
        return text;
      },
    },
  ];

  return (
    <Modal
      title="Student Details"
      forceRender
      visible={modalVisible}
      onOk={() => {}}
      onCancel={closeModal}
      okText="Delete"
      cancelText="cancel"
      width="800px"
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <div>
        <div>
          <h2>Student Info</h2>
          <div style={{ marginTop: "16px", marginBottom: "16px" }}>
            {isEditing ? (
              <>
                <Button
                  type="primary"
                  onClick={handleSave}
                  style={{ marginRight: "8px" }}
                >
                  Save
                </Button>
                <Button onClick={handleCancelEdit}>Cancel</Button>
              </>
            ) : (
              <Button type="primary" onClick={() => setIsEditing(true)}>
                Edit
              </Button>
            )}
          </div>
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
        <br />
        <div>
          <h2>Uploaded Files</h2>
          <ul>
            <li>
              <ReadOnlyUploadedFile
                FileLabel="Photograph"
                FileURL={rower.Photograph}
              />
            </li>
            <li>
              <ReadOnlyUploadedFile
                FileLabel="CNIC / Passport Scan"
                FileURL={rower.CNIC}
              />
            </li>
            <li>
              <ReadOnlyUploadedFile
                FileLabel="Declaration"
                FileURL={rower.Declaration}
              />
            </li>
            {rower.Vaccination ? (
              <li>
                <ReadOnlyUploadedFile
                  FileLabel="Vaccination"
                  FileURL={rower.Vaccination}
                />
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
        <div>
          <h2>Events</h2>
          {evententries.length < 1 ? <div>No events</div> : null}
          {console.log(evententries.length)}
          {evententries.map((event) => {
            console.log(event);
            return <div>{event}</div>;
          })}
        </div>
      </div>
    </Modal>
  );

  function GetDataSource(rower) {
    var mappings = {
      Name: rower.Name,
      YOB: rower.YOB,
      "Age Group": rower.AgeGroup,
      Gender: rower.Gender,
      EmailAddress: rower.EmailAddress,
      PhoneNumber: rower.PhoneNumber,
      Nationality: rower.Nationality,
      CNIC: rower.CNICNumber,
      "KBC Membership Status": rower.KBCMember,
      "KBC Number": rower.KBCNumber,
      "Participated in previous KBC": rower.PreviousKBC ? "Yes" : "No",
    };

    var keys = Object.keys(mappings);
    var dataSource = [];
    keys.forEach((key) => {
      dataSource.push({
        Key: key,
        Value: mappings[key],
      });
    });
    return dataSource;
  }
}
