import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router";
import styles from "./StudentRegistrations.module.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  DatePicker,
  Select,
  Space,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { GetRower } from "../../APIManager";
const EditStudentInfo = () => {

  return (
    <div>
      <h1>Edit Student Info </h1>
    </div>
  );
};

export default EditStudentInfo;
