import { React, useState } from "react";
import { Button, Upload, Modal, Form, Space } from "antd";
import { DownloadFile } from "../../APIManager";
import { useForm } from "antd/lib/form/Form";
import ImgCrop from "antd-img-crop";
export default function ReadOnlyUploadedFile({ FileLabel, FileIdentifier, FileURL }) {
  if (!FileURL) {
    return <></>;
  }
  return (
    <div>
      <Button
        onClick={() => {
          OnDownloadClick();
        }}
        type="link"
        size="large"
      >
        {FileLabel}
      </Button>
    </div>
  );
  function OnDownloadClick() {
    DownloadFile(FileURL);
  }
}
