import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  DatePicker,
  Select,
  Space,
  message,
} from "antd";
import {} from "../../APIManager";
import "./YouTubeEmbeded.css";

const YoutubeModal = ({ modalVisible, closeModal, videoID }) => {
  return (
    <Modal
      title="Watch Video"
      forceRender
      visible={modalVisible}
      onOk={() => {}}
      onCancel={closeModal}
      okText="Delete"
      cancelText="Close"
      width="1000px"
      okButtonProps={{
        hidden: true,
      }}
      maskClosable={true}
    >
      <div className="video-responsive">
        <iframe
          src={`https://www.youtube.com/embed/${videoID}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </Modal>
  );
};

export default YoutubeModal;
