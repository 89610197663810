import { React, useContext } from "react";
import { Table, Button } from "antd";
import { UserContext } from "../../UserContext";
import UploadedFile from "../Shared Components/UploadedFile";
import {
  UpdateSchoolBestCoxNomination,
  UpdateSchoolLogo,
  UpdateSchoolDeclaration,
  getLoggedInUser,
  UpdateSchoolInfo,
} from "../../APIManager";
import { useHistory } from "react-router";

export default function ViewSchoolInfoPage() {
  const history = useHistory();
  const [user, setUser] = useContext(UserContext);
  const dataSource = GetDataSource(user);
  const columns = [
    {
      dataIndex: "Key",
      key: "Key",
    },
    {
      dataIndex: "Value",
      key: "Value",
      render: (text, record) => {
        if (record?.Key.includes("Rowing Color")) {
          return (
            <div
              style={{
                backgroundColor: record.Value,
                borderWidth: "2px",
                borderStyle: "solid",
              }}
            >
              {record.Value}
            </div>
          );
        } else {
          return record.Value;
        }
      },
    },
  ];

  return (
    <div>
      <div>
        <h2>
          School Info{" "}
          <Button
            type="primary"
            onClick={() => {
              history.push("/editschoolinfo");
            }}
            disabled={user.stagescomplete.includes("Final Submission")}
          >
            Edit
          </Button>
        </h2>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
      <br />
      <div>
        <h2>Uploaded Files</h2>
        <ul>
          <li>
            <UploadedFile
              FileLabel="School Declaration"
              FileIdentifier={"schooldeclaration"}
              FileURL={user.schooldeclaration}
              FileType="DocumentScan"
              UploadAPIFunction={(file) => {
                UpdateSchoolDeclaration(file).then((res) => {
                  window.location.reload();
                });
              }}
            />
          </li>
          <li>
            <UploadedFile
              FileLabel="School Logo (min 300dpi)"
              FileIdentifier={"schoollogo"}
              FileURL={user.schoollogo}
              FileType="SchoolLogo"
              UploadAPIFunction={(file) => {
                UpdateSchoolLogo(file).then((res) => {
                  window.location.reload();
                });
              }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
  function RefreshLoggedInUser() {
    getLoggedInUser().then((res) => {
      setUser(res.data);
    });
  }
}

function GetDataSource(user) {
  console.log(user);
  var mappings = {
    "School Name": user.schoolname,
    "School Initials": user.schoolinitials,
    "Rowing Color 1": user.rowingcolors[0],
    "Rowing Color 2": user.rowingcolors[1],
    "Rowing Color 3": user.rowingcolors[2],
    "School Address": user.address,
    "School Email Address": user.emailaddress,
    Website: user.website,
    "AGM Voter": user.agmvotename,
    "Principal Name": user.principal.Name,
    "Principal Title": user.principal.Title,
    "Principal Email": user.principal.Email,
    "Principal Phone Number": user.principal.PhoneNumber,
    "Rowing Coordinator Name": user.rowingcoordinator.Name,
    "Rowing Coordinator Title": user.rowingcoordinator.Title,
    "Rowing Coordinator Email": user.rowingcoordinator.Email,
    "Rowing Coordinator Phone Number": user.rowingcoordinator.PhoneNumber,
    "Coach Name": user.coach.Name,
    "Coach Email": user.coach.Email,
    "Coach Phone Number": user.coach.PhoneNumber,
    "Cox Nomination": user.bestcoxnomination,
    "Cox Nomaintion Justification": user.bestcoxnominationjustification,
  };

  var keys = Object.keys(mappings);
  var dataSource = [];
  keys.forEach((key) => {
    dataSource.push({
      Key: key,
      Value: mappings[key],
    });
  });
  return dataSource;
}
