import React, { useState, useContext, useEffect } from "react";
import styles from "./TeamEventRegistrations.module.css";
import { UserContext } from "../../UserContext";
import {
  getLoggedInUser,
  AddEventEntries,
  GetRaceEntries,
  DeleteRaceEntry,
} from "../../APIManager";
import AddNewAssigment from "./AddNewAssignment";
import UpdateAssignment from "./UpdateAssignment";

import { SplitEntriesByCategory as ProcessEntries } from "../../utility";

import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  AutoComplete,
  Space,
  Tag,
  Row,
  Col,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { map } from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card } from "antd";
const { confirm } = Modal;
const TeamEventRegistrations = () => {
  let [userContext, SetUserContext] = useContext(UserContext);
  const [isAssignModalOpen, SetIsAssignModalOpen] = useState(false);
  const [isUpdateModalOpen, SetIsUpdateModalOpen] = useState(false);
  const [selectedRaceToAssign, SetSelectedRaceToAssign] = useState("");
  const [raceEntries, SetRaceEntries] = useState([]);
  const eventEntries = userContext.evententries;
  const proccesedEventEntries = ProcessEntries(eventEntries);

  useEffect(() => {
    FetchEntries();
  }, []);

  function FetchEntries() {
    GetRaceEntries().then((res) => {
      SetRaceEntries(res.data);
    });
  }

  return (
    <div className={styles.MainDiv}>
      <AddNewAssigment
        visible={isAssignModalOpen}
        onCancel={onAssignCancel}
        race={selectedRaceToAssign}
        onSubmit={() => {
          SetIsAssignModalOpen(false);
          FetchEntries();
          //Refresh page data
        }}
        raceEntries={raceEntries}
      />
      {
        <UpdateAssignment
          visible={isUpdateModalOpen}
          onCancel={onAssignCancel}
          race={selectedRaceToAssign}
          onSubmit={() => {
            SetIsUpdateModalOpen(false);
            FetchEntries();
            //Refresh page data
          }}
          raceEntries={raceEntries}
        />
      }
      <div className={styles.SubHeading}> Team Event Registrations Page</div>

      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <CategoryBlock
            Label="Boys Under 25 Water Races"
            Entries={proccesedEventEntries.U25MaleWR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />

          <CategoryBlock
            Label="Boys Under 25 Indoor Races"
            Entries={proccesedEventEntries.U25MaleIR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />

          <CategoryBlock
            Label="Boys Under 21 Water Races"
            Entries={proccesedEventEntries.U21MaleWR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Boys Under 21 Indoor Races"
            Entries={proccesedEventEntries.U21MaleIR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Boys Under 18 Water Races"
            Entries={proccesedEventEntries.U18MaleWR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Boys Under 18 Indoor Races"
            Entries={proccesedEventEntries.U18MaleIR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Boys Under 15 Water Races"
            Entries={proccesedEventEntries.U15MaleWR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Boys Under 15 Indoor Races"
            Entries={proccesedEventEntries.U15MaleIR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
        </Col>
        <Col className="gutter-row" span={12}>
          <CategoryBlock
            Label="Girls Under 25 Water Races"
            Entries={proccesedEventEntries.U25FemaleWR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />

          <CategoryBlock
            Label="Girls Under 25 Indoor Races"
            Entries={proccesedEventEntries.U25FemaleIR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Girls Under 21 Water Races"
            Entries={proccesedEventEntries.U21FemaleWR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Girls Under 21 Indoor Races"
            Entries={proccesedEventEntries.U21FemaleIR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Girls Under 18 Water Races"
            Entries={proccesedEventEntries.U18FemaleWR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Girls Under 18 Indoor Races"
            Entries={proccesedEventEntries.U18FemaleIR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Girls Under 15 Water Races"
            Entries={proccesedEventEntries.U15FemaleWR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
          <CategoryBlock
            Label="Girls Under 15 Indoor Races"
            Entries={proccesedEventEntries.U15FemaleIR}
            OnAssign={OnAssign}
            OnDelete={OnDelete}
            raceEntries={raceEntries}
          />
        </Col>
      </Row>
    </div>
  );

  function OnAssign(race, isEdit) {
    if (isEdit) {
      SetIsUpdateModalOpen(true);
    } else {
      SetIsAssignModalOpen(true);
    }
    SetSelectedRaceToAssign(race);
  }

  function onAssignCancel() {
    SetIsAssignModalOpen(false);
    SetIsUpdateModalOpen(false);
    SetSelectedRaceToAssign("");
  }

  function OnDelete(race) {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this race entry?",
      okText: "Delete",
      okType: "primary",
      cancelText: "Cancel",
      onOk() {
        DeleteRaceEntry(race)
          .then(() => {
            FetchEntries();
          })
          .catch(() => {
            Modal.info({
              title: "A Server / Network error has occured",
              content: (
                <div>
                  Unable to delete race entry. Please check you internet, or
                  contact KBC
                </div>
              ),
              onOk() {},
            });
          });
      },
      onCancel() {},
    });
  }
};

export default TeamEventRegistrations;

const CategoryBlock = ({
  Label,
  Gender,
  AgeGroup,
  Entries,
  OnAssign,
  raceEntries,
  OnDelete,
}) => {
  return (
    <Card title={Label} className={styles.CategoryCard}>
      {Entries.length === 0 ? <div>No Entries for this catergory</div> : <></>}
      {Entries.map(function (item, i) {
        var rowers;
        var coxers;
        var subrowers;
        var subcoxers;
        raceEntries?.forEach((entry) => {
          if (entry.RaceName === item) {
            rowers = map(entry.Rowers, "Name");
            coxers = map(entry.Coxers, "Name");
            subrowers = map(entry.SubRowers, "Name");
            subcoxers = map(entry.SubCoxers, "Name");
          }
        });
        return (
          <RaceBlock
            key={i}
            Label={item}
            onAssign={OnAssign}
            OnDelete={OnDelete}
            rowers={rowers}
            coxers={coxers}
            subrowers={subrowers}
            subcoxers={subcoxers}
          />
        );
      })}
    </Card>
  );
};

const RaceBlock = ({
  Label,
  onAssign,
  rowers,
  coxers,
  subrowers,
  subcoxers,
  OnDelete,
}) => {
  let [userContext, SetUserContext] = useContext(UserContext);
  console.log(rowers);

  if (rowers?.length >= 1) {
    return (
      <div className={styles.RaceBlock}>
        <div className={styles.RaceBlockName}>{Label}</div>
        <div style={{ marginBottom: "1em", lineHeight: "2em" }}>
          <span hidden={!rowers}>
            Rowers:{" "}
            {rowers.map(function (name, i) {
              return (
                <Tag color="green" style={{ fontSize: "1em" }}>
                  {name}
                </Tag>
              );
            })}
          </span>
          <span hidden={coxers?.length < 1}>
            Cox:{" "}
            {coxers.map(function (name, i) {
              return (
                <Tag color="blue" style={{ fontSize: "1em" }}>
                  {name}
                </Tag>
              );
            })}
          </span>
          <span hidden={subrowers?.length < 1}>
            Sub Rowers:{" "}
            {subrowers.map(function (name, i) {
              return (
                <Tag color="magenta" style={{ fontSize: "1em" }}>
                  {name}
                </Tag>
              );
            })}
          </span>
          <span hidden={subcoxers?.length < 1}>
            Sub Cox:{" "}
            {subcoxers.map(function (name, i) {
              return (
                <Tag color="magenta" style={{ fontSize: "1em" }}>
                  {name}
                </Tag>
              );
            })}
          </span>
        </div>
        <Button
          disabled={userContext.stagescomplete.includes("Final Submission")}
          onClick={() => onAssign(Label, true)}
          type="primary"
          style={{ marginRight: "1em" }}
        >
          Edit
        </Button>
        <Button
          disabled={userContext.stagescomplete.includes("Final Submission")}
          onClick={() => OnDelete(Label)}
          type="danger"
        >
          Delete Entry
        </Button>
      </div>
    );
  } else {
    return (
      <div className={styles.RaceBlock}>
        <div className={styles.RaceBlockName}>{Label}</div>
        <div>No Rowers have been assigned yet</div>
        <Button
          disabled={userContext.stagescomplete.includes("Final Submission")}
          onClick={() => onAssign(Label)}
          type="primary"
        >
          Assign
        </Button>
      </div>
    );
  }
};
