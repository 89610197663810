import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import styles from "./StudentRegistrations.module.css";
import { getLoggedInUser, AddEventEntries } from "../../APIManager";
import AddNewStudent from "./AddNewStudent";
import DeleteStudent from "./DeleteStudent";
import ViewStudentInformation from "./ViewStudentInformation";
import { UserContext } from "../../UserContext";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  AutoComplete,
  Space,
} from "antd";

import { reverse, result, find, filter } from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";

import { GetRowers } from "../../APIManager";
import { Table } from "antd";
const { confirm } = Modal;
const StudentRegistrations = () => {
  const history = useHistory();
  const [user, setUser] = useContext(UserContext);
  const [isNewModalVisible, SetIsNewModalVisible] = useState(false);
  const [isDeleteModalVisible, SetIsDeleteModalVisible] = useState(false);
  const [isViewModalVisible, SetIsViewModalVisible] = useState(false);
  const [SelectedUserID, SetSelectedUserID] = useState("");
  const [students, SetStudents] = useState([]);
  useEffect(() => {
    FetchStudents();
  }, []);

  function FetchStudents() {
    GetRowers().then((res) => {
      SetStudents(res.data.reverse());
    });
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      sortDirections: ["ascend", "descend"],
      onFilter: (value, record) => record.Name.indexOf(value) === 0,
    },
    {
      title: "KBC Number",
      dataIndex: "KBCNumber",
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      sorter: (a, b) => a.Gender.localeCompare(b.Gender),
      sortDirections: ["ascend", "descend"],
      filters: [
        {
          text: "Male",
          value: "Male",
        },
        {
          text: "Female",
          value: "Female",
        },
      ],
      onFilter: (value, record) => record.Gender.indexOf(value) === 0,
    },
    {
      title: "Category",
      dataIndex: "AgeGroup",
      sorter: (a, b) => a.AgeGroup.localeCompare(b.AgeGroup),
      sortDirections: ["ascend", "descend"],
      filters: [
        {
          text: "Under 15",
          value: "Under 15",
        },
        {
          text: "Under 18",
          value: "Under 18",
        },
        {
          text: "Under 21",
          value: "Under 21",
        },
        {
          text: "Under 25",
          value: "Under 25",
        },
      ],
      onFilter: (value, record) => record.AgeGroup.indexOf(value) === 0,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a
            disabled={user.stagescomplete.includes("Final Submission")}
            onClick={() => {
              if (user.stagescomplete.includes("Final Submission")) {
                return;
              }
              SetSelectedUserID(record._id);
              SetIsDeleteModalVisible(true);
            }}
          >
            Delete
          </a>
          <a
            onClick={() => {
              SetSelectedUserID(record._id);
              SetIsViewModalVisible(true);
            }}
          >
            Details
          </a>
        </Space>
      ),
    },
  ];
  return (
    <div className={styles.MainDiv}>
      <Button
        disabled={user.stagescomplete.includes("Final Submission")}
        onClick={() => {
          confirm({
            title: "Confirm",
            icon: <InfoCircleOutlined />,
            content: (
              <div>
                Before adding new student ensure you have the following files
                ready to upload (You may also upload these later):
                <div>
                  <ul>
                    <li>Student Photograph (.PNG or .JPEG)</li>
                    <li>Scanned Student CNIC/Passport(.PDF)</li>
                    <li>Scanned Student Declaration</li>
                  </ul>
                </div>
              </div>
            ),
            okText: "Proceed",
            okType: "primary",
            cancelText: "Back",
            onOk() {
              SetIsNewModalVisible(true);
            },
            onCancel() {},
          });
        }}
        type="primary"
      >
        Add New Student
      </Button>
      <StudentStats students={students} />
      <AddNewStudent
        visible={isNewModalVisible}
        onSubmit={() => {
          FetchStudents();
          SetIsNewModalVisible(false);
        }}
        onCancel={() => {
          SetIsNewModalVisible(false);
        }}
      />
      <DeleteStudent
        studentID={SelectedUserID}
        modalVisible={isDeleteModalVisible}
        closeModal={() => {
          SetIsDeleteModalVisible(false);
        }}
        OnModalSubmit={() => {
          FetchStudents();
        }}
      />
      <ViewStudentInformation
        rowerid={SelectedUserID}
        modalVisible={isViewModalVisible}
        closeModal={() => {
          SetIsViewModalVisible(false);
        }}
        onSubmit={FetchStudents}
      />
      <Table
        columns={columns}
        dataSource={students}
        pagination={{ defaultPageSize: 50 }}
      />
    </div>
  );
};

const StudentStats = (props) => {
  const { students } = props;
  var totalStudents = students.length;
  var u15Boys = FilterByCategory(students, "Male", "Under 15").length;
  var u18Boys = FilterByCategory(students, "Male", "Under 18").length;
  var u21Boys = FilterByCategory(students, "Male", "Under 21").length;
  var u25Boys = FilterByCategory(students, "Male", "Under 25").length;
  var totalBoys = u15Boys + u18Boys + u21Boys + u25Boys;

  var u15Girls = FilterByCategory(students, "Female", "Under 15").length;
  var u18Girls = FilterByCategory(students, "Female", "Under 18").length;
  var u21Girls = FilterByCategory(students, "Female", "Under 21").length;
  var u25Girls = FilterByCategory(students, "Female", "Under 25").length;
  var totalGirls = u15Girls + u18Girls + u21Girls + u25Girls;

  return (
    <div>
      <h3>Total Students: {students?.length}</h3>
      <h3>
        Boys: {totalBoys} (U15: {u15Boys} | U18: {u18Boys} | U21: {u21Boys} |
        U25: {u25Boys})
      </h3>
      <h3>
        Girls: {totalGirls} (U15: {u15Girls} | U18: {u18Girls} | U21: {u21Girls}{" "}
        | U25: {u25Girls})
      </h3>
    </div>
  );
};

function FilterByCategory(students, gender, category) {
  var filtered = filter(students, (student) => {
    return student.AgeGroup === category && student.Gender === gender;
  });
  return filtered;
}

export default StudentRegistrations;
