import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { Layout } from "antd";

import { ProtectedRoute } from "../../ProtectedRoute";

import styles from "./MainLayout.module.css";
import SideBar from "./SideBar";
//Pages
import HomePage from "../HomePage/HomePage";
import SchoolInfoPage from "../SchoolInfo/SchoolInfoPage";
import EditSchoolInfoPage from "../SchoolInfo/EditSchoolInfoPage";
import EventEntries from "../EventEntries/EventEntries";
import StudentRegistrations from "../StudentRegistrations/StudentRegistrations";
import RowerFileUploads from "../RowerFileUploads/RowerFileUploads";
import EditStudentInfo from "../StudentRegistrations/EditStudentInfo";
import TeamRoles from "../TeamRoles/TeamRoles";
import NewTeamRoles from "../TeamRoles/NewTeamRoles";
import TeamEventRegistrations from "../TeamEventRegistrations/TeamEventRegistrations";
import AppHeader from "./AppHeader";
import SubmissionPage from "../Submission/SubmissionPage";
import Instructions from "../Instructions/Instructions";
import EventInstructions from "../Event Instructions/EventInstructions";
import PaymentPage from "../Payment/PaymentPage";
import { ConfigContext } from "../../ConfigContext";
const { Header, Content, Footer, Sider } = Layout;
const MainLayout = () => {
  const location = useLocation();
  const history = useHistory();
  const selectedKey = GetSelectedKey();

  let [config, setConfig] = useContext(ConfigContext);

  return (
    <div>
      <Layout className={styles.MainLayout}>
        <SideBar />
        <Layout className={styles.siteLayout}>
          <Header
            className={styles.siteLayoutBackground}
            style={{ padding: 0 }}
          >
            <AppHeader />
          </Header>
          <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
            <div
              className={styles.siteLayoutBackground}
              style={{ padding: 24 }}
            >
              <Switch>
                <ProtectedRoute
                  exact
                  path="/"
                  Component={HomePage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/schoolinfo"
                  Component={SchoolInfoPage}
                  props={{ Hello: "Hello" }}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/editschoolinfo"
                  Component={EditSchoolInfoPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/evententries"
                  Component={EventEntries}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/studentregistrations"
                  Component={StudentRegistrations}
                ></ProtectedRoute>
                <ProtectedRoute exact path="/editstudentinfo/:id">
                  <EditStudentInfo />
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/teamroles"
                  Component={NewTeamRoles}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/teamregistrations"
                  Component={TeamEventRegistrations}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/payment"
                  Component={PaymentPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/submission"
                  Component={SubmissionPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/instructions"
                  Component={Instructions}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/eventinstructions"
                  Component={EventInstructions}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/studentfileuploads"
                  Component={RowerFileUploads}
                ></ProtectedRoute>
              </Switch>
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "1.1em",
            }}
          >
            Karachi Boat Club © {config.cpConfig.EventYear} | Website created by
            Muhammad Umar Mahar
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
  function GetSelectedKey() {
    var path = location.pathname;
    switch (path) {
      case "/":
        return "1";
      case "/schoolinfo":
        return "2";
      case "/evententries":
        return "3";
      case "/studentregistrations":
        return "4";
      case "/teamregistrations":
        return "5";
      default:
        break;
    }
  }
};

export default MainLayout;
