import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../UserContext";
import AddSchoolInfoPage from "./AddSchoolInfoPage";
import ViewSchoolInfoPage from "./ViewSchoolInfoPage";
const SchoolInfoPage = () => {
  const [user, setUser] = useContext(UserContext);
  const [editMode, SetEditMode] = useState(false);
  if (user.stagescomplete.includes("School Info") && !editMode) {
    return <ViewSchoolInfoPage />;
  } else if (user.stagescomplete.includes("School Info") && !user.stagescomplete.includes("Final Submission") && editMode) {
    return "Edit Mode";
  } else {
    return <AddSchoolInfoPage />;
  }
};

export default SchoolInfoPage;
