import React, { useState, useContext, useEffect } from "react";
import {
  getLoggedInUser,
  AddEventEntries,
  GetFormCheckList,
  Logout,
  GetCMSDocument,
  GetFileDocuments,
} from "../../APIManager";
import { useHistory } from "react-router";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import ParticipantDecalarationDoc from "../../Static/Documents/ISR 2024 Participant Declaration Form.pdf";
import SchoolDeclarationDoc from "../../Static/Documents/ISR 2024 School Declaration Form.pdf";
import RulesRegulationsBook from "../../Static/Documents/Rules Governing Races and Logisitics.pdf";

import ProtestForm from "../../Static/Documents/Form - Protest Processing - 2024.01.pdf";
import SubstitutionForm from "../../Static/Documents/Form - Substitution Request 2024.01.pdf";

import bulletin from "../../Static/Documents/ISR 2024 Bulletin.pdf";

import RuleBook from "../../Static/Documents/Rule Book.pdf";

import kbcBG from "../../Static/homeBG.jpg";

import Countdown from "react-countdown";

import YoutubeModal from "./YoutubeModal";

import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  Card,
  Col,
  Row,
  List,
  Skeleton,
} from "antd";
import styles from "./HomePage.module.css";
import { UserContext } from "../../UserContext";
import { ConfigContext } from "../../ConfigContext";

const HomePage = () => {
  let history = useHistory();
  let [userContext, SetUserContext] = useContext(UserContext);
  let [config, setConfig] = useContext(ConfigContext);

  const [demovideoModalOpen, SetDemovideoModalOpen] = useState(false);

  const [cmsAnnouncement, SetCMSAnnouncement] = useState("");
  const [filedocuments, SetFileDocuments] = useState([]);

  const [checklist, SetChecklist] = useState(null);

  useEffect(() => {
    GetCMSDocument("ClientHomePageAnnouncement").then((res) => {
      SetCMSAnnouncement(res?.data?.Data?.Announcement);
      console.log(res?.data?.Data?.Announcement);
    });

    GetFileDocuments().then((res) => {
      SetFileDocuments(res.data);
    });

    GetFormCheckList().then((res) => {
      SetChecklist(res.data);
    });
  }, []);

  return (
    <div className={styles.mainDIV}>
      <Button
        size={"large"}
        type=""
        onClick={() => {
          Logout().then((res) => {
            SetUserContext(res.data);
            history.push("/login");
          });
        }}
      >
        Log Out
      </Button>
      {/* <Button
        size={"large"}
        type="primary"
        onClick={() => {
          SetDemovideoModalOpen(true);
        }}
        style={{ marginLeft: "1em" }}
      >
        Watch Demo Video
      </Button> */}
      <YoutubeModal
        videoID={"47LBE8i97sg"}
        modalVisible={demovideoModalOpen}
        closeModal={() => {
          SetDemovideoModalOpen(false);
        }}
      />
      <div className={styles.SchoolName}>{userContext.username}</div>
      <div className="site-card-wrapper" style={{ marginTop: "2em" }}>
        <Row gutter={16}>
          <Col span={12}>
            <Card title="" bordered={false} className={styles.Card}>
              <EntriesCheckListCard config={config} />
            </Card>
            <Card
              title="Main Form Checklist"
              bordered={false}
              className={styles.Card}
              style={{ marginTop: "1em" }}
            >
              <MainCheckListCard
                user={userContext}
                config={config}
                checklist={checklist}
              />
            </Card>

            {/*  <Card
              title="Payment /Flag Checklist"
              bordered={false}
              className={styles.Card}
              style={{ marginTop: "2em" }}
            >
              <PaymentCheckListCard />
            </Card> */}
          </Col>
          <Col span={12}>
            <Card
              title="Forms to Print"
              bordered={false}
              className={styles.Card}
            >
              {filedocuments
                .filter((d) => d.Section == "Documents to print")
                .filter(
                  (d) =>
                    !(
                      d.Tags.includes("HidePreEvent") &&
                      !checklist?.FinalSubmission
                    )
                )
                .filter(
                  (d) =>
                    !(
                      d.Tags.includes("HidePostRegister") &&
                      checklist?.FinalSubmission
                    )
                )
                .map((d) => (
                  <>
                    {" "}
                    <a
                      className={styles.downloadlink}
                      href={d.S3Object.url}
                      download={d.S3Object.fileName}
                      target="_blank"
                    >
                      {" "}
                      Download {d.Name}
                    </a>
                    <br />
                  </>
                ))}
            </Card>
            <br />
            <Card
              title="Announcements"
              bordered={false}
              className={styles.Card}
            >
              {filedocuments
                .filter((d) => d.Section == "Announcements")
                .map((d) => (
                  <>
                    {" "}
                    <a
                      className={styles.downloadlink}
                      href={d.S3Object.url}
                      download={d.S3Object.fileName}
                      target="_blank"
                    >
                      {" "}
                      Download {d.Name}
                    </a>
                    <br />
                  </>
                ))}
              {/*               <br />
              <a
                className={styles.downloadlink}
                href={RuleBook}
                download="Rule Book.pdf"
              >
                {" "}
                Download Rule Book{" "}
              </a> */}
              <hr />
              {/*  <div>
                <strong>Please make note of the following corrections:</strong>
                <br />
                1) A field for adding a substitute for the single scull (1x) has
                been added.
                <br />
                2)The information about cox weight has been corrected in the
                Event Registration Rules. <br />
                (50kg minimum for boys’ and girls’ events. Coxes weighing
                35-49kg must carry sandbags. Less than 35 kg not allowed.)
              </div> <hr /> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: ` ${cmsAnnouncement}`,
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const MainCheckListCard = ({ user, config, checklist }) => {
  if (!checklist) {
    return <Skeleton />;
  }
  return (
    <div className={styles.CheckList}>
      <span>
        <strong>Deadline: {config.cpConfig.SecondDeadlineText}</strong> <br />
        <Countdown
          date={new Date(config.cpConfig.SecondDeadlineDate)}
          renderer={TimerRenderer}
        />
      </span>
      <hr />
      School Info{" "}
      {checklist.SchoolInfo ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone twoToneColor="#eb2f96" />
      )}{" "}
      <hr />
      Team Registrations{" "}
      {checklist.TeamRegistrations ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone twoToneColor="#eb2f96" />
      )}{" "}
      <hr />
      File Uploads{" "}
      {checklist.FileUploads ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone twoToneColor="#eb2f96" />
      )}{" "}
      <hr />
      Team Roles{" "}
      {checklist.TeamRoles ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone twoToneColor="#eb2f96" />
      )}{" "}
      <hr />
      Event Registrations{" "}
      {checklist.EventRegistrations ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <>
          <CloseCircleTwoTone twoToneColor="#eb2f96" />{" "}
          {checklist.EventRegistrationsInfo.Assigned} /{" "}
          {checklist.EventRegistrationsInfo.Total}
        </>
      )}{" "}
      <hr />
      Final Submission{" "}
      {checklist.FinalSubmission ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone twoToneColor="#eb2f96" />
      )}{" "}
      <hr />
      <div hidden={user.otherattr?.includes("Payment Waived")}>
        {" "}
        Payment{" "}
        {checklist.Payment ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#eb2f96" />
        )}{" "}
      </div>
      <br />
    </div>
  );
};
const PaymentCheckListCard = () => {
  const [checklist, SetChecklist] = useState(null);
  useEffect(() => {
    GetFormCheckList().then((res) => {
      SetChecklist(res.data);
    });
  }, []);
  if (!checklist) {
    return <Skeleton />;
  }
  return (
    <div className={styles.CheckList}>
      <span>
        <strong>Deadline: Saturday, Jan 14, 2023 </strong>
      </span>
      <hr />
      Payment / Flag Submission{" "}
      {checklist.Payment ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone twoToneColor="#eb2f96" />
      )}{" "}
      <hr />
    </div>
  );
};

const EntriesCheckListCard = ({ config }) => {
  const [checklist, SetChecklist] = useState(null);
  useEffect(() => {
    GetFormCheckList().then((res) => {
      SetChecklist(res.data);
    });
  }, []);
  if (!checklist) {
    return <Skeleton />;
  }
  return (
    <div className={styles.CheckList}>
      <span>
        <strong>Deadline: {config.cpConfig.FirstDeadlineText} </strong>
        <br />
        <Countdown
          date={new Date(config.cpConfig.FirstDeadlineDate)}
          renderer={TimerRenderer}
        />
      </span>
      <br />
      <hr />
      Event Entries{" "}
      {checklist.EventEntries ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone twoToneColor="#eb2f96" />
      )}
      <hr />
      Flag Submission{" "}
      {checklist.Flag ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <CloseCircleTwoTone twoToneColor="#eb2f96" />
      )}{" "}
    </div>
  );
};
export default HomePage;

const TimerRenderer = ({ days, hours, minutes, seconds, completed }) => {
  return (
    <span
      className={styles.CountDown}
    >{`${days} days ${hours} hours ${minutes} minutes ${seconds} seconds left`}</span>
  );
};
