import { React, useState, useContext } from "react";
import { Button, Upload, Modal, Form, Space } from "antd";
import { DownloadFile } from "../../APIManager";
import { useForm } from "antd/lib/form/Form";
import ImgCrop from "antd-img-crop";
import { UserContext } from "../../UserContext";
export default function UploadedFile({
  FileLabel,
  FileIdentifier,
  FileURL,
  newUploadMode,
  FileType, // DocumentScan, StudentPhoto, SchoolLogo
  UploadAPIFunction,
  OnSubmit,
  dontShowFileLabel,
  modalTitle,
  buttonJSX,
}) {
  const [isModalVisible, SetIsModalVisible] = useState(false);
  const [user, setUser] = useContext(UserContext);
  return (
    <span>
      {isModalVisible ? (
        <PopUpWindow
          isModalVisible={isModalVisible}
          FileType={FileType}
          onCancel={() => {
            SetIsModalVisible(false);
          }}
          ModalTitle={modalTitle ? modalTitle : FileLabel}
          OnChange={UploadAPIFunction}
          onSubmit={() => {
            SetIsModalVisible(false);
            OnSubmit();
          }}
          OnDownloadClick={OnDownloadClick}
          FileLabel={FileLabel}
        />
      ) : (
        <></>
      )}
      {!dontShowFileLabel && (
        <Button
          onClick={() => {
            OnDownloadClick();
          }}
          type="link"
          size="large"
        >
          {FileLabel}
        </Button>
      )}
      <Button
        disabled={user.stagescomplete.includes("Final Submission")}
        type="ghost"
        onClick={newUploadMode ? OnNewClick : OnEditClick}
      >
        {buttonJSX ? buttonJSX : newUploadMode ? "Upload" : "Change"}
      </Button>
    </span>
  );
  function OnDownloadClick() {
    if (newUploadMode) {
      return;
    }
    DownloadFile(FileURL);
  }
  function OnNewClick() {
    SetIsModalVisible(true);
  }
  function OnEditClick() {
    SetIsModalVisible(true);
  }
}

const PopUpWindow = ({
  isModalVisible,
  onCancel,
  onSubmit,
  ModalTitle,
  FileType,
  OnChange,
  OnDownloadClick,
  FileLabel,
}) => {
  const [form] = Form.useForm();
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);
  function onFinish() {
    SetWaitingOnResponse(false);
    OnChange(form.getFieldsValue().Upload);
    onSubmit();
  }
  return (
    <Modal
      title={ModalTitle}
      visible={isModalVisible}
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
      onCancel={onCancel}
    >
      {FileLabel && (
        <Button
          onClick={() => {
            OnDownloadClick();
          }}
          type="Primary"
          size="large"
        >
          {FileLabel}
        </Button>
      )}
      <Form
        form={form}
        layout={"vertical"}
        size="large"
        autoComplete={false}
        onFinish={onFinish}
      >
        <Form.Item
          label="Upload"
          name="Upload"
          rules={[
            {
              required: true,
              message: "Please upload new file",
            },
          ]}
        >
          {GetUploadComponent(FileType)}
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => {
            return (
              <Space
                style={{ justifyContent: "flex-end", width: "100%" }}
                width="100%"
                align="end"
              >
                <Button type="default" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  size={"large"}
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched() ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length ||
                    waitingOnResponse
                  }
                >
                  Submit
                </Button>
              </Space>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
  function GetUploadComponent(FileType) {
    switch (FileType) {
      case "DocumentScan":
        return <DocumentUpload />;
      case "StudentPhoto":
        return <StudentPhotoFileUpload />;
      case "SchoolLogo":
        return <LogoFileUpload />;
      default:
        break;
    }
    return;
  }
};

const DocumentUpload = ({ value, onChange, onUpload }) => {
  return (
    <Upload
      maxCount={1}
      accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      onRemove={() => {
        onChange("");
      }}
      onChange={() => {}}
      beforeUpload={(file) => {
        let reader = new FileReader();
        reader.addEventListener("load", function (e) {
          let text = e.target.result;
          onChange(text);
        });
        reader.readAsDataURL(file);

        // Prevent upload
        return false;
      }}
    >
      <Button>Select File</Button>
    </Upload>
  );
};

const LogoFileUpload = ({ value, onChange, onUpload }) => {
  return (
    <ImgCrop
      rotate
      aspect={1 / 1}
      minZoom={0.1}
      cropperProps={{ restrictPosition: false }}
    >
      <Upload
        maxCount={1}
        accept=".png,.jpg,.jpeg"
        listType="picture-card"
        onRemove={() => {
          onChange("");
        }}
        onChange={() => {}}
        beforeUpload={(file) => {
          let reader = new FileReader();
          reader.addEventListener("load", function (e) {
            let text = e.target.result;
            onChange(text);
          });
          reader.readAsDataURL(file);
          //Dont Prevent upload to avoid thumbnail bug hence returning true
          return true;
        }}
      >
        <Button>Select File</Button>
      </Upload>
    </ImgCrop>
  );
};

const StudentPhotoFileUpload = ({ value, onChange, onUpload }) => {
  return (
    <ImgCrop rotate aspect={35 / 45}>
      <Upload
        maxCount={1}
        accept=".png,.jpg,.jpeg"
        listType="picture-card"
        onRemove={() => {
          onChange("");
        }}
        onChange={() => {}}
        beforeUpload={(file) => {
          let reader = new FileReader();
          reader.addEventListener("load", function (e) {
            let text = e.target.result;
            onChange(text);
          });
          reader.readAsDataURL(file);
          //Dont Prevent upload to avoid thumbnail bug hence returning true
          return true;
        }}
      >
        <Button>Select File</Button>
      </Upload>
    </ImgCrop>
  );
};
