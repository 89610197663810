import React, { useContext, useState, useEffect } from "react";
import { stringConstants } from "../../constants";
import { UserContext } from "../../UserContext";

import { GetCMSDocument } from "../../APIManager";

import { ConfigContext } from "../../ConfigContext";

export default function Instructions() {
  const [user, SetUser] = useContext(UserContext);
  const [config] = useContext(ConfigContext);
  const [instructions, SetInstructions] = useState({
    InternationalInstructions: "Loading",
    LocalInstructions: "Loading",
  });

  function ReplaceVariables(template) {
    return template.replace(
      /{stringConstants\.([\w]+)}/g, // Regex to match {stringConstants.PropertyName}
      (_, key) => config.cpConfig[key] || `{stringConstants.${key}}` // Replace with value or keep the placeholder if not found
    );
  }

  useEffect(() => {
    GetCMSDocument("ClientInstructionsPageInstructions").then((res) => {
      SetInstructions(res?.data?.Data);
    });
  }, []);

  if (user.international) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: ` ${ReplaceVariables(
            instructions.InternationalInstructionsInstructions
          )}`,
        }}
      />
    );

    /*  <div>
        <h1 style={{ fontSize: "2em", textAlign: "center" }}>Instructions</h1>
        <ol>
          <li>
            <strong>Print out signature forms </strong>
            <ol>
              <li>
                School Declaration Form - Have it signed and stamped by the
                school.
              </li>
              <li>
                Participant Declaration - Give a copy to each participant.
              </li>
            </ol>
          </li>
          <li>
            <strong>Gather documents and data from students </strong>
            <div>
              Start collecting these as soon as possible! The system will
              require the following information/documents for each participant
              in order to save his or her record in the registration database.
              Please make sure all scans are clear and legible.
            </div>
            <div>For every student, make sure you have:</div>
            <ol>
              <li>Passport copy - Scan</li>
              <ul>
                <li>
                  The name on this document must be used for registration and
                  will appear on student certificates. KBC will not be
                  responsible if wrong name or spelling is provided
                </li>
              </ul>
              <li>Photograph - Requirements</li>
              <ul>
                <li>
                  Photo must be a soft copy of a photograph provided by a
                  professional photographer. Snapshots/scans of hard copy
                  photographs will not be accepted
                </li>
                <li>Photo must be taken on a white background</li>
                <li>Photo must meet PAKISTAN VISA requirements</li>

                <li>Photo must not be older than 6 months</li>
                <li>Format must be JPEG/JPG/PNG</li>
                <li>
                  Once uploaded, please adjust photo so that the candidate’s
                  head fills the marked space
                </li>
              </ul>
              <li>Signed participant declaration form - Scan</li>
              <ul>
                <li>
                  Students born in {stringConstants.U18YOBLowerLimit} or after
                  (17 years old and younger) must also obtain parent signature
                </li>
              </ul>
            </ol>
          </li>
          <li>
            <strong>
              Submission Deadline for Event Entries:{" "}
              {stringConstants.FirstDeadlineText}
            </strong>
          </li>
          <li>
            <strong>
              Submission Deadline for Online Registration Form:{" "}
              {stringConstants.SecondDeadlineText}.
            </strong>
          </li>

          <li>
            <strong>
              Student Pick-up of Regatta ID Cards, dates and times to be
              announced Each student must personally pick up their Regatta ID
              card from the club.
            </strong>{" "}
            In order to receive their card each student must show the original
            ID document, which must perfectly match the scan that was uploaded
            into their registration record. No card will be released until the
            original document has been presented at the club.
          </li>

          <li>
            <strong>
              Falsification of official government documents is a serious
              offence.{" "}
            </strong>
            If any discrepancy is found between the submitted scan and the
            original document, consequences will be severe: at a minimum a ban
            for this and all future rowing competitions.
          </li>

          <li>
            If you come across any issues while filling out the form or if you
            have any questions, kindly contact the Regatta Secretary via email.
            <br />
            Email:{" "}
            <a href={`mailto:${stringConstants.RegattaSecEmail}`}>
              {stringConstants.RegattaSecEmail}
            </a>
          </li>
        </ol>
      </div> */
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: ` ${ReplaceVariables(instructions.LocalInstructions)}`,
      }}
    />
  );
  /*  <div>
      <h1 style={{ fontSize: "2em", textAlign: "center" }}>Instructions</h1>
      <ol>
        <li>
          <strong>Print out signature forms </strong>
          <ol>
            <li>
              School Declaration Form - Have it signed and stamped by the
              school.
            </li>
            <li>Participant Declaration - Give a copy to each participant.</li>
          </ol>
        </li>
        <li>
          <strong>Gather documents and data from students </strong>
          <div>
            Start collecting these as soon as possible! The system will require
            the following information/documents for each participant in order to
            save his or her record in the registration database. Please make
            sure all scans are clear and legible.
          </div>
          <div>For every student, make sure you have:</div>
          <ol>
            <li>CNIC copy - Scan</li>
            <ul>
              <li>
                Only in case CNIC is not available, English language B-form or
                passport copy may be submitted
              </li>
              <li>
                The name on this document must be used for registration and will
                appear on student certificates. KBC will not be responsible if
                wrong name or spelling is provided
              </li>
            </ul>
            <li>Photograph - Requirements</li>
            <ul>
              <li>
                Photo must be a soft copy of a photograph provided by a
                professional photographer. Snapshots/scans of hard copy
                photographs will not be accepted
              </li>
              <li>Photo must be taken on a white background</li>
              <li>Photo must meet NADRA requirements</li>

              <li>Photo must not be older than 6 months</li>
              <li>Format must be JPEG/JPG/PNG</li>
              <li>
                Once uploaded, please adjust photo so that the candidate’s head
                fills the marked space
              </li>
            </ul>
            <li>Signed participant declaration form - Scan</li>
            <ul>
              <li>
                Students born in {stringConstants.U18YOBLowerLimit} or after (17
                years old and younger) must also obtain parent signature
              </li>
            </ul>
            <li>
              KBC membership number - for all Regular KBC Members and Rowing
              Members
            </li>
          </ol>
        </li>
        <li>
          <strong>
            Submission Deadline for Event Entries and School Flag:{" "}
            {stringConstants.FirstDeadlineText}
          </strong>
        </li>
        <li>
          <strong>
            Submission Deadline for Online Registration Form and Payment:{" "}
            {stringConstants.SecondDeadlineText}.
            <br />
            Bring the following to your appointment:
          </strong>
          <ul>
            <li>Fee Challan (printed from online application)</li>
          </ul>
          <ul>
            <li>Payment</li>
          </ul>
        </li>
        <br />
        <li>
          <strong>
            Student Pick-up of Regatta ID Cards, dates and times to be announced
            Each student must personally pick up their Regatta ID card from the
            club.
          </strong>{" "}
          In order to receive their card each student must show the original ID
          document, which must perfectly match the scan that was uploaded into
          their registration record. No card will be released until the original
          document has been presented at the club.
        </li>
        <br />
        <li>
          <strong>
            Falsification of official government documents is a serious offence.{" "}
          </strong>
          If any discrepancy is found between the submitted scan and the
          original document, consequences will be severe: at a minimum a ban for
          this and all future rowing competitions.
        </li>
        <br />
        <li>
          <strong>Late submission:</strong>
          <ol>
            <li>
              The Regatta Committee reserves the right to decide whether to
              allow forms to be submitted late or not. Any school permitted to
              submit late will pay:
              <ul>
                <li>
                  Rs. 2000 for the school and Rs. 500 per participant for each
                  subsequent day.
                </li>
              </ul>
            </li>
            <li>
              There will be no refund of fees for schools/rowers who do not
              participate in the competition.
            </li>
          </ol>
        </li>
        <li>
          If you come across any issues while filling out the form or if you
          have any questions, kindly contact the Regatta Secretary via email.
          <br />
          Email:{" "}
          <a href={`mailto:${stringConstants.RegattaSecEmail}`}>
            {stringConstants.RegattaSecEmail}
          </a>
        </li>
      </ol>
    </div> */
}
