export function GetAgeBracketInfo(cpConfig) {
  const { EventYear } = cpConfig;

  const U25YOBLowerLimit = EventYear ? EventYear - 24 : null;
  const U21YOBLowerLimit = EventYear ? EventYear - 20 : null;
  const U18YOBLowerLimit = EventYear ? EventYear - 17 : null;
  const U15YOBLowerLimit = EventYear ? EventYear - 14 : null;

  return {
    U25YOBLowerLimit,
    U21YOBLowerLimit,
    U18YOBLowerLimit,
    U15YOBLowerLimit,
  };
}

export function SplitEntriesByCategory(entries) {
  var entiresbycat = {
    U25MaleWR: [],
    U25FemaleWR: [],

    U25MaleIR: [],
    U25FemaleIR: [],

    U21MaleWR: [],
    U21MaleIR: [],

    U21FemaleWR: [],
    U21FemaleIR: [],

    U18MaleWR: [],
    U18MaleIR: [],

    U18FemaleWR: [],
    U18FemaleIR: [],

    U15MaleWR: [],
    U15MaleIR: [],

    U15FemaleWR: [],
    U15FemaleIR: [],
  };
  entries.forEach((e) => {
    var arrayname = "";
    if (e.includes("25")) {
      arrayname = arrayname + "U25";
    }
    if (e.includes("21")) {
      arrayname = arrayname + "U21";
    }
    if (e.includes("18")) {
      arrayname = arrayname + "U18";
    }
    if (e.includes("15")) {
      arrayname = arrayname + "U15";
    }
    if (e.includes("Boys")) {
      arrayname = arrayname + "Male";
    }
    if (e.includes("Girls")) {
      arrayname = arrayname + "Female";
    }
    if (e.includes("Scull") || e.includes("Pair") || e.includes("Coxed")) {
      arrayname = arrayname + "WR";
    }
    if (!e.includes("Scull") && !e.includes("Pair") && !e.includes("Coxed")) {
      arrayname = arrayname + "IR";
    }
    entiresbycat[arrayname].push(e);
  });
  return entiresbycat;
  console.log(entiresbycat);
}

export function CatCodeToFullform(categoryCode) {
  switch (categoryCode) {
    case "U25MaleWR":
      return "Under 25 Male Water Races";
      break;
    case "U25FemaleWR":
      return "Under 25 Female Water Races";
      break;
    case "U25MaleIR":
      return "Under 25 Male Indoor Races";
      break;
    case "U25FemaleIR":
      return "Under 25 Female Indoor Races";
      break;
    case "U21MaleWR":
      return "Under 21 Male Water Races";
      break;
    case "U21MaleIR":
      return "Under 21 Male Indoor Races";
      break;
    case "U21FemaleWR":
      return "Under 21 Female Water Races";
      break;
    case "U21FemaleIR":
      return "Under 21 Female Indoor Races";
      break;

    case "U18MaleWR":
      return "Under 18 Male Water Races";
      break;
    case "U18MaleIR":
      return "Under 18 Male Indoor Races";
      break;
    case "U18FemaleWR":
      return "Under 18 Female Water Races";
      break;
    case "U18FemaleIR":
      return "Under 18 Female Indoor Races";
      break;

    case "U15MaleWR":
      return "Under 15 Male Water Races";
      break;
    case "U15MaleIR":
      return "Under 15 Male Indoor Races";
      break;
    case "U15FemaleWR":
      return "Under 15 Female Water Races";
      break;
    case "U15FemaleIR":
      return "Under 15 Female Indoor Races";
      break;
  }
}

export const U21MaleWR = [
  { Label: "Single Scull 1X", Value: "U21MWRSS1X" },
  { Label: "Double Scull 2X", Value: "U21MWRDS2X" },
  { Label: "Pair 2-", Value: "U21MWRP2-" },
  { Label: "Quadruple Scull 4x", Value: "U21MWRQS4X" },
  { Label: "Coxed Four 4+", Value: "U21MWRCF4+" },
  { Label: "Coxed Eight 8+", Value: "U21MWRCE8+" },
];
export const U21MaleIR = [
  { Label: "1 x 500m", Value: "U21MIR1X500" },
  { Label: "1 x 2000m", Value: "U21MIR1X2000" },
  { Label: "1 x 5000m", Value: "U21MIR1X5000" },
  { Label: "2 x 1000m Relay", Value: "U21MIR2X1000" },
  { Label: "4 x 500m Relay", Value: "U21MIR4X500" },
];
export const U21FemaleWR = [
  { Label: "Single Scull 1X", Value: "U21FWRSS1X" },
  { Label: "Double Scull 2X", Value: "U21FWRDS2X" },
  { Label: "Pair 2-", Value: "U21FWRP2-" },
  { Label: "Quadruple Scull 4x", Value: "U21FWRQS4X" },
  { Label: "Coxed Four 4+", Value: "U21FWRCF4+" },
  { Label: "Coxed Eight 8+", Value: "U21FWRCE8+" },
];
export const U21FemaleIR = [
  { Label: "1 x 500m", Value: "U21FIR1X500" },
  { Label: "1 x 2000m", Value: "U21FIR1X2000" },
  { Label: "1 x 5000m", Value: "U21FIR1X5000" },
  { Label: "2 x 1000m Relay", Value: "U21FIR2X1000" },
  { Label: "4 x 500m Relay", Value: "U21FIR4X500" },
];
export const U18MaleWR = [
  { Label: "Single Scull 1X", Value: "U18MWRSS1X" },
  { Label: "Double Scull 2X", Value: "U18MWRDS2X" },
  { Label: "Pair 2-", Value: "U18MWRP2-" },
  { Label: "Quadruple Scull 4x", Value: "U18MWRQS4X" },
  { Label: "Coxed Four 4+", Value: "U18MWRCF4+" },
  { Label: "Coxed Eight 8+", Value: "U18MWRCE8+" },
];
export const U18MaleIR = [
  { Label: "1 x 500m", Value: "U18MIR1X500" },
  { Label: "1 x 2000m", Value: "U18MIR1X2000" },
  { Label: "1 x 5000m", Value: "U18MIR1X5000" },
  { Label: "2 x 1000m Relay", Value: "U18MIR2X1000" },
  { Label: "4 x 500m Relay", Value: "U18MIR4X500" },
];
export const U18FemaleWR = [
  { Label: "Single Scull 1X", Value: "U18FWRSS1X" },
  { Label: "Double Scull 2X", Value: "U18FWRDS2X" },
  { Label: "Pair 2-", Value: "U18FWRP2-" },
  { Label: "Quadruple Scull 4x", Value: "U18FWRQS4X" },
  { Label: "Coxed Four 4+", Value: "U18FWRCF4+" },
  { Label: "Coxed Eight 8+", Value: "U18FWRCE8+" },
];
export const U18FemaleIR = [
  { Label: "1 x 500m", Value: "U18FIR1X500" },
  { Label: "1 x 2000m", Value: "U18FIR1X2000" },
  { Label: "1 x 5000m", Value: "U18FIR1X5000" },
  { Label: "2 x 1000m Relay", Value: "U18FIR2X1000" },
  { Label: "4 x 500m Relay", Value: "U18FIR4X500" },
];

export const U15MaleWR = [
  { Label: "Single Scull 1X", Value: "U15MWRSS1X" },
  { Label: "Double Scull 2X", Value: "U15MWRDS2X" },
  { Label: "Quadruple Scull 4x", Value: "U15MWRQS4X" },
];
export const U15MaleIR = [
  { Label: "1 x 500m", Value: "U15MIR1X500" },
  { Label: "2 x 1000m Relay", Value: "U15MIR2X1000" },
  { Label: "4 x 500m Relay", Value: "U15MIR4X500" },
];

export const U15FemaleWR = [
  { Label: "Single Scull 1X", Value: "U15FWRSS1X" },
  { Label: "Double Scull 2X", Value: "U15FWRDS2X" },
  { Label: "Quadruple Scull 4x", Value: "U15FWRQS4X" },
];
export const U15FemaleIR = [
  { Label: "1 x 500m", Value: "U15FIR1X500" },
  { Label: "2 x 1000m Relay", Value: "U15FIR2X1000" },
  { Label: "4 x 500m Relay", Value: "U15FIR4X500" },
];
