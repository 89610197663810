import React, { useState, useEffect, useContext } from "react";

import { UserContext } from "../../UserContext";
import {
  GetRowers,
  DownloadFile,
  UpdateStudentPhotograph,
  UpdateStudentDeclaration,
  UpdateStudentCNIC,
} from "../../APIManager";

import { Button, Modal, Space, Table } from "antd";

import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import UploadPhotograph from "./UploadPhotograph";

import UploadedFile from "../Shared Components/UploadedFile";

export default function RowerFileUploads() {
  const [user, setUser] = useContext(UserContext);
  const [SelectedUserID, SetSelectedUserID] = useState("");
  const [students, SetStudents] = useState([]);
  const [photographModalOpen, SetPhotographModalOpen] = useState(false);
  const [cnicModalOpen, SetCNICModalOpen] = useState(false);
  const [declarationModalOpen, SetDeclarationModalOpen] = useState(false);
  const [isChangeMode, SetIsChangeMode] = useState(false);

  const selectedStudent = students.filter((s) => s._id == SelectedUserID)[0];

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      sortDirections: ["ascend", "descend"],
      onFilter: (value, record) => record.Name.indexOf(value) === 0,
    },
    {
      title: "CNIC Number",
      dataIndex: "CNICNumber",
    },
    {
      title: "Photograph",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a
            disabled={user.stagescomplete.includes("Final Submission")}
            onClick={() => {
              if (user.stagescomplete.includes("Final Submission")) {
                return;
              }
              SetSelectedUserID(record._id);
              SetPhotographModalOpen(true);
            }}
          >
            {record.Photograph ? (
              <>
                <span>
                  {" "}
                  <UploadedFile
                    FileLabel={"View Photograph"}
                    FileURL={selectedStudent?.Photograph}
                    newUploadMode={false}
                    FileType={"StudentPhoto"}
                    UploadAPIFunction={(file) => {
                      UpdateStudentPhotograph(
                        file,
                        selectedStudent?.Name,
                        selectedStudent.KBCNumber,
                        selectedStudent._id
                      ).then(() => {
                        FetchStudents();
                      });
                    }}
                    OnSubmit={() => {}}
                    dontShowFileLabel
                    modalTitle={`Change photograph of ${selectedStudent?.Name}`}
                    buttonJSX={
                      <>
                        {" "}
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Change
                      </>
                    }
                  />
                </span>
              </>
            ) : (
              <>
                {" "}
                <span>
                  {" "}
                  <UploadedFile
                    FileLabel={""}
                    FileURL={selectedStudent?.Photograph}
                    newUploadMode={true}
                    FileType={"StudentPhoto"}
                    UploadAPIFunction={(file) => {
                      UpdateStudentPhotograph(
                        file,
                        selectedStudent?.Name,
                        selectedStudent.KBCNumber,
                        selectedStudent._id
                      ).then(() => {
                        FetchStudents();
                      });
                    }}
                    OnSubmit={() => {}}
                    dontShowFileLabel
                    modalTitle={`Upload photograph of ${selectedStudent?.Name}`}
                    buttonJSX={
                      <>
                        {" "}
                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                        Upload
                      </>
                    }
                  />
                </span>
              </>
            )}
          </a>
        </Space>
      ),
    },
    {
      title: "CNIC/Passport",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a
            disabled={user.stagescomplete.includes("Final Submission")}
            onClick={() => {
              if (user.stagescomplete.includes("Final Submission")) {
                return;
              }
              SetSelectedUserID(record._id);
              SetCNICModalOpen(true);
            }}
          >
            {record.CNIC ? (
              <>
                <span>
                  {" "}
                  <UploadedFile
                    FileLabel={"View CNIC"}
                    FileURL={selectedStudent?.CNIC}
                    newUploadMode={false}
                    FileType={"DocumentScan"}
                    UploadAPIFunction={(file) => {
                      UpdateStudentCNIC(
                        file,
                        selectedStudent?.Name,
                        selectedStudent.KBCNumber,
                        selectedStudent._id
                      ).then(() => {
                        FetchStudents();
                      });
                    }}
                    OnSubmit={() => {}}
                    dontShowFileLabel
                    modalTitle={`Change CNIC of ${selectedStudent?.Name}`}
                    buttonJSX={
                      <>
                        {" "}
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Change
                      </>
                    }
                  />
                </span>
              </>
            ) : (
              <>
                {" "}
                <span>
                  {" "}
                  <UploadedFile
                    FileLabel={""}
                    FileURL={selectedStudent?.CNIC}
                    newUploadMode={true}
                    FileType={"DocumentScan"}
                    UploadAPIFunction={(file) => {
                      UpdateStudentCNIC(
                        file,
                        selectedStudent?.Name,
                        selectedStudent.KBCNumber,
                        selectedStudent._id
                      ).then(() => {
                        FetchStudents();
                      });
                    }}
                    OnSubmit={() => {}}
                    dontShowFileLabel
                    modalTitle={`Upload CNIC of ${selectedStudent?.Name}`}
                    buttonJSX={
                      <>
                        {" "}
                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                        Upload
                      </>
                    }
                  />
                </span>
              </>
            )}
          </a>
        </Space>
      ),
    },
    {
      title: "Declaration",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a
            disabled={user.stagescomplete.includes("Final Submission")}
            onClick={() => {
              if (user.stagescomplete.includes("Final Submission")) {
                return;
              }
              SetSelectedUserID(record._id);
              SetDeclarationModalOpen(true);
            }}
          >
            {record.Declaration ? (
              <>
                <span>
                  {" "}
                  <UploadedFile
                    FileLabel={"View Declaration"}
                    FileURL={selectedStudent?.Declaration}
                    newUploadMode={false}
                    FileType={"DocumentScan"}
                    UploadAPIFunction={(file) => {
                      UpdateStudentDeclaration(
                        file,
                        selectedStudent?.Name,
                        selectedStudent.KBCNumber,
                        selectedStudent._id
                      ).then(() => {
                        FetchStudents();
                      });
                    }}
                    OnSubmit={() => {}}
                    dontShowFileLabel
                    modalTitle={`Change Declaration of ${selectedStudent?.Name}`}
                    buttonJSX={
                      <>
                        {" "}
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        Change
                      </>
                    }
                  />
                </span>
              </>
            ) : (
              <>
                {" "}
                <span>
                  {" "}
                  <UploadedFile
                    FileLabel={""}
                    FileURL={selectedStudent?.Declaration}
                    newUploadMode={true}
                    FileType={"DocumentScan"}
                    UploadAPIFunction={(file) => {
                      UpdateStudentDeclaration(
                        file,
                        selectedStudent?.Name,
                        selectedStudent.KBCNumber,
                        selectedStudent._id
                      ).then(() => {
                        FetchStudents();
                      });
                    }}
                    OnSubmit={() => {}}
                    dontShowFileLabel
                    modalTitle={`Upload Declaration of ${selectedStudent?.Name}`}
                    buttonJSX={
                      <>
                        {" "}
                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                        Upload
                      </>
                    }
                  />
                </span>
              </>
            )}
          </a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    FetchStudents();
  }, []);

  function FetchStudents() {
    GetRowers().then((res) => {
      SetStudents(res.data.reverse());
    });
  }

  return (
    <div>
      {" "}
      <Table
        columns={columns}
        dataSource={students}
        pagination={{ defaultPageSize: 200 }}
      />
    </div>
  );
}
