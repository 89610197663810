import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router";
import styles from "./StudentRegistrations.module.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  DatePicker,
  Select,
  Space,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  GetRower,
  GetIsRowerValidToDelete,
  GetDeleteStudent,
} from "../../APIManager";
const DeleteStudent = ({
  studentID,
  modalVisible,
  closeModal,
  OnModalSubmit,
}) => {
  const [student, SetStudent] = useState({});
  const [validToDelete, SetValidToDelete] = useState(false);
  const [entries, SetEntries] = useState([]);

  const [response, SetResponse] = useState();

  useEffect(() => {
    SetStudent({});
    SetValidToDelete(false);
    SetEntries([]);
    GetIsRowerValidToDelete(studentID).then((res) => {
      SetResponse({ ...res.data });
      SetValidToDelete(res.data.IsValidToDelete);
      SetEntries(res.data.Entries);
      GetRower(studentID).then((res) => {
        SetStudent(res.data);
      });
    });
  }, [studentID]);

  function onDeleteClick() {
    GetDeleteStudent(studentID).then((res) => {
      OnModalSubmit();
    });
    closeModal();
  }

  return (
    <Modal
      title="Delete Student"
      forceRender
      visible={modalVisible}
      onOk={() => {}}
      onCancel={closeModal}
      okText="Delete"
      cancelText="cancel"
      width="800px"
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      {!validToDelete && student.Name && entries.length > 0 && (
        <div>
          You cannot delete <strong>{student.Name}</strong> since they are
          participating in the following events. Please remove them from these
          events in order to delete
          <br />
          <ul>
            {entries?.map((entry) => {
              return <li>{entry}</li>;
            })}
          </ul>
        </div>
      )}

      {!validToDelete && student.Name && response.isCaptain > 0 && (
        <div>{student.Name} is the Team Captain and cannot be deleted</div>
      )}

      {!validToDelete && student.Name && response.isViceCaptain > 0 && (
        <div>{student.Name} is the Team Vice Captain and cannot be deleted</div>
      )}
      {!validToDelete && student.Name && response.isCox > 0 && (
        <div>{student.Name} is a Team Cox and cannot be deleted</div>
      )}

      {validToDelete && student.Name ? (
        <div>
          Are you sure you want to delete <br />
          Name: <strong>{student.Name}</strong>
          <br />
          KBC Number: <strong>{student.KBCNumber}</strong>
          <br />
          <Button
            onClick={() => {
              onDeleteClick();
            }}
            type="danger"
          >
            Delete
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            type="primary"
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </Button>
        </div>
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default DeleteStudent;
