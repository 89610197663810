import { React, useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";
import { Result, Button, Typography, Skeleton } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { GetFormCheckList, SubmitFinalForm } from "../../APIManager";
import { ConfigContext } from "../../ConfigContext";

const { Paragraph, Text } = Typography;
export default function SubmissionPage() {
  const [checklist, SetChecklist] = useState(null);
  const [config] = useContext(ConfigContext);

  useEffect(() => {
    GetFormCheckList().then((res) => {
      SetChecklist(res.data);
    });
  }, []);
  const [user, setUser] = useContext(UserContext);
  if (user.stagescomplete.includes("Final Submission")) {
    return (
      <Result
        status="success"
        title="Registration Form has been successfuly submitted"
        subTitle=""
      />
    );
  } else {
    if (!checklist) {
      return <Skeleton />;
    }

    var today = new Date();
    var deadline = new Date(config.cpConfig.SecondDeadlineDate);
    var beforeDeadline = today < deadline;

    var errors = [];
    if (
      !beforeDeadline &&
      !user.otherattr.includes("FinalSubmissionExtension") &&
      config.cpConfig.LockOn2ndDeadline
    ) {
      errors.push(
        "Deadline to submit form has elasped. Please contact the ISR Secretary for more information"
      );
    }
    if (!checklist.EventEntries) {
      errors.push("Event Entries form not submitted");
    }
    if (!checklist.Flag) {
      errors.push("Flag must be submitted in person at KBC");
    }
    if (!checklist.Payment) {
      errors.push("Payment must be submitted in person at KBC");
    }
    if (!checklist.SchoolInfo) {
      errors.push("School Info form not saved");
    }
    if (!checklist.TeamRegistrations) {
      errors.push("Rowers not added");
    }
    if (!checklist.FileUploads) {
      errors.push("File uploads missing for some rowers");
    }
    if (!checklist.TeamRoles) {
      errors.push("Team Roles form not saved");
    }
    /*  if (!checklist.Payment) {
      errors.push("Fee payment not submitted");
    } */
    if (!checklist.EventRegistrations) {
      errors.push(`Event Registrations form not completed (${checklist.EventRegistrationsInfo.Assigned} /
        ${checklist.EventRegistrationsInfo.Total})`);
    }
    console.log(errors.length);
    return (
      <Result
        status="error"
        title="Form has not been submitted yet"
        subTitle="Please verify all information entered in the previous sections before submitting the form"
        extra={[
          <Button
            disabled={errors.length}
            type="primary"
            key="console"
            size="large"
            onClick={() => {
              SubmitFinalForm().then((res) => {
                console.log("hello");
                window.location.reload();
              });
            }}
          >
            Submit Form
          </Button>,
        ]}
      >
        <div className="desc" hidden={!errors.length}>
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              Following must be completed before submitting form:
            </Text>
          </Paragraph>
          {errors.map((error) => {
            return (
              <Paragraph>
                <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                {error}
              </Paragraph>
            );
          })}
        </div>
      </Result>
    );
  }
}
